import { Link } from "react-router-dom";
import { useRef, useState } from "react";

const Login = () => {
    const username = useRef('');
    const password = useRef('');
    const [error, setError] = useState('');
    const login = async (evt) => {
        let allinvalid = Array.from(document.querySelectorAll('.is-invalid'));
        allinvalid.map(el => el.classList.remove('is-invalid'));
        let reem = new RegExp(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/);
        let stop = false;
        if (!reem.test(username.current.value)) { stop = true;
            username.current.classList.add('is-invalid');
        }
        if (password.current.value.length < 5) { stop = true;
            password.current.classList.add('is-invalid');
        }
        if (stop) { return false; }
        var btn = evt.target;
        btn.disabled = true;
        btn.innerHTML = "Logging in...";
        let dt = JSON.stringify({username: username.current.value, password: password.current.value});
        let res = await fetch('https://api.trishika.co/auth/login',
        {
            headers:
                {'Content-Type':'application/json'},
            method: 'post',
            credentials: 'include',
            body: dt
        });
        switch(res.status) {
            case 202:
                window.location.href = '/dashboard';
                break;
            case 200:
                window.location.href = '/checkout';
                break;
            case 401:
                let tx = await res.text();
                btn.disabled = false;
                btn.innerHTML = "Sign in";
                password.current.value = '';
                username.current.value = '';
                setError(tx);
                break;
            default:
                break;
        }
    }
    return <div className="col-md-5">
    <div className="login-box">
    <h3>Login</h3>
        <div className="row">
        <div className="col-sm-12 my-3">
            <input type="email" ref={username} className="form-control" id="inputEmail3" placeholder="Email" />
            <div className="invalid-feedback">Please enter your correct email address</div>
        </div>
        <div className="col-sm-12">
            <input type="password" ref={password} className="form-control" id="inputPassword3" placeholder="Password" />
        </div>
        </div>
        <div className="col-sm-12">
        <button className="btn btn-primary theme-btn mt-3" onClick={login}>Sign in</button>
        {(error !== '') ? <div className="alert alert-danger rounded-5 p-2 mt-3 ps-3">{error}</div> : <></>}
        </div>
        <div className="text-end">
            <Link to="/retrieve">Reset Password</Link>
        </div>
    </div>
</div>
}

export default Login;