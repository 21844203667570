const Orderitem = (props) => {
    return <div className="cardashian px-4 pt-2 mb-5 rounded-4">
        <div className="row border-bottom pb-3 my-3">
        <div className="col-8 fw-bolder">Items</div>
        <div className="col-4 fw-bolder">Price</div>
        </div><div className="row border-bottom mb-3">
    <div className="col-8">
        {(props.particulars).map((el, id) => <p key={id}><span>{el.item} x {el.itemquantity}</span></p>)}
        </div><div className="col-4">₹{props.total}</div>
</div>
<div className="row border-bottom mb-3">
<div className="col-5 col-md-4">
    <div className="fw-bold"><p>Shipping Address:</p></div>
</div>
<div className="col-7 col-md-8">
    <b>{props.address.name}</b><br />
    <span>{props.address.address1}</span><br />
    <span>{props.address.address2}</span><br />
    <span>{props.address.city}, {props.address.state}</span><br />
    <span>{props.address.zipcode} 📞 {props.address.phone}</span>
</div>
</div>
<div className="row">
<div className="col-5 col-md-4">
    <div className="fw-bold"><p>Dispatch status:</p></div>
</div>
<div className="col-7 col-md-8">{(props.shippingStatus) ? <div><p className="fw-bolder success">Shipped | <a className="fw-bolder" href={props.shippingTrackLink} target="_blank" rel="noreferrer noopener">Click to Track</a></p>
    <p>Tracking ID: {props.shippingTrackID}</p></div> : <>{(props.txref === 'FAILED') ? <p>PAYMENT FAILED</p> : <p>Preparing to dispatch</p>}</>}</div>
</div></div>}

export default Orderitem;
