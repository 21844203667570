import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import App from './screens/App';
import Auth from './screens/auth';
import Checkout from './screens/checkout';
import Contact from './screens/contact';
import Confirmation from './screens/confirmation';
import Orders from './screens/orders';
import Profile from './screens/profile';
import Pages from './screens/page';
import Pcb from './utils/paymentcallback';
import Pst from './utils/paymentstatus';
import Dashboard from './screens/dashboard';
import Retrieve from './screens/forgot';
import Logout from './utils/logout';

const root = ReactDOM.createRoot(document.getElementById('IMGroot'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/dashboard" element={<Dashboard section="orders" />} />
      <Route path="/dashboard/settings" element={<Dashboard section="settings" />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/received" element={<Confirmation />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/edit-profile" element={<Profile />} />
      <Route path="/payment-cb" element={<Pcb />} />
      <Route path="/payment-status" element={<Pst />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/retrieve" element={<Retrieve />} />
      <Route path="/pages/:page" element={<Pages />} />
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);