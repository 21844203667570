import { useState, useRef, useCallback, useEffect } from 'react';
import Earrings from './earrings';
import Cardbody from './cardbody';
import Previewarea from './preview';

const Productselector = () => {
    const URL = 'https://api.trishika.co';
    const [goldcolor, setGoldcolor] = useState('yellow');
    const [kt, setKt] = useState('14');
    const [diamondprice, setDiamondprice] = useState('');
    const diamond = useRef();
    const animatebuybox = useRef();
    const [caratprice, setCaratprice] = useState('');
    const [jacketprice, setJacketprice] = useState('');
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const diamondselector = useRef();
    const [lim, setLim] = useState('/images/earrings/apple/EARRING-6-5-1.jpg');
    useEffect(() => {
        fetch(URL + '/fetchprice/'+kt).then(
            res => res.json()
        ).then(rj => rj.map(r => {
            if (r.product.startsWith('J_')) {
                updateJacketprice(r.price);
            };
            return r;
        }));
        // eslint-disable-next-line
    }, [kt]);
    const changelim = (rt, name, image) => {
        let x;
        if (cart.length === 0) {
            x = [];
            x[0] = {type:'NOSTUD', price: 0, carat: kt, qty: 0};
            x[1] = { type:name, imgsrc:image, price: jacketprice, carat: kt, qty: 1, gold: goldcolor };
        } else {
            x = [...cart];
            let found = false;
            x.map(xr => {
                if (xr.type === name) {
                    found = true; xr.qty += 1;
                } return xr;
            });
            if (!found) {
                if(cart.length === 1){
                    x = [...cart, { type:name, imgsrc:image, price: 0, carat: kt, qty: 1, gold: goldcolor }];
                }else{
                    x = [...cart, { type:name, imgsrc:image, price: jacketprice, carat: kt, qty: 1, gold: goldcolor }];
                }
                
            }
        }
        setCart(x);
        setLim(rt);
        updatetotal(x);
    }
    const removeFromCart = useCallback((index, name) => {
        const x = [...cart];
        if (index === 0) {
            x[0] = {type:'NOSTUD', price: 0, carat: kt, qty: 0};
            diamond.current.childNodes[0].checked = false;
            diamond.current.childNodes[2].checked = false;
            setDiamondprice('');
            setCart(x);
            updatetotal(x);
            return;
        }
        if (typeof(x[index]) === 'undefined') {
            return;
        }
        if (x[index]['type'] !== name) {
            return;
        }
        x.splice(index, 1);
        setCart(x);
        updatetotal(x);
    }, [cart, kt]);
    const updateJacketprice = (price) => {
        let c = [...cart];
        c.map(cx => { if (cx.type !== 'STUD') { cx.price = price } return cx; });
        setJacketprice(price);
        updatetotal(c);
    }
    const setCartQty = (index, qty) => {
        if (qty === 0) {
            return false; // Stop conflict with removeFromCart
        }
        let x = [...cart];
        x[index].qty = qty;
        setCart(x);
        updatetotal(x);
    }
    const updatetotal = (c) => {
        if (c.length > 0) {
            let _total = 0;
            c.map(crt => _total += crt.price*crt.qty);
            setTotal(_total);
        } else {
            setTotal(0);
        }
    }
    const buynow = (evt) => {
        if (total <= 0) {
            alert('Please add some items to the cart.');
            return;
        }
        let c = [...cart];
        c.map(x => x.gold = goldcolor);
        evt.target.disabled = true;
        let r = evt.target.innerHTML;
        evt.target.innerText = "Processing...";
        animatebuybox.current.classList.add('d-flex');
        fetch(URL + '/cartloaded', { headers: {'Content-Type':'application/json'}, method: 'post',
        body: JSON.stringify(c), credentials: 'include' })
            .catch((err) => {
                evt.target.disabled = false;
                alert(err.message + ". Please try again later. A connection hiccup maybe!");
                evt.target.innerHTML = r;
                return;
            })
            .then((res) => {
                if (!evt.target.disabled) { return; }
        animatebuybox.current.childNodes[1].classList.add('ringsanimate');
        animatebuybox.current.classList.add('addtocartanimation');
            if (res.status === 201) {
                setTimeout(() => { window.location.href = '/checkout'; }, 3000);
            } else {
                setTimeout(() => { window.location.href = '/auth'; }, 3000);
            }
		return res.json();
        });
    }
    const diamondchanged = (evt) => {
        fetch(URL + '/fetchprice/_'+evt.target.value).then(
            res => res.json()
        ).then(rj => {
            let dprice = rj.price;
            setDiamondprice('₹' + dprice);
            if (caratprice !== '') {
                let studprice = caratprice;
                let c = [...cart];
                let dp = parseInt(dprice);
                studprice = parseInt(studprice.substring(1));
                if (c.length > 0) {
                    c[0] = { type:'STUD', price: studprice+dp, carat: kt, qty: c[0].qty || 1, gold: goldcolor };
                } else {
                    c[0] = { type:'STUD', price: parseInt(studprice)+dp, carat: kt, qty: 1, gold: goldcolor };
                }
                setCart(c);
                updatetotal(c);
            }
            return rj;
        });
    }
    const goldcolorChanged = (evt) => {
        setGoldcolor(evt.target.value);
    };
    const caratChanged = (evt) => {
        let addToCart = true;
        let dp = diamondprice;
        if (dp === '') {
            diamondselector.current.classList.add('attention');
            setTimeout(() => diamondselector.current.classList.remove('attention'), 3000);
            addToCart = false;
        }
        let ct = (evt.target.value === '8') ? '18' : '14';
        setKt(ct);
        fetch(URL + '/fetchprice/'+evt.target.value).then(
            res => res.json()
        ).then(rj => rj.map(r => {
            if (r.product.startsWith('J_')) {
                updateJacketprice(r.price);
            } else {
                setCaratprice('₹' + r.price);
                if (addToCart) {
                    let c = [...cart];
                    dp = parseInt(dp.substring(1));
                    if (c.length === 0) {
                        c[0] = { type:'STUD', price: parseInt(r.price)+dp, carat: ct, qty: 1, gold: goldcolor };
                    } else {
                        let q = (c[0].qty !== 0) ? c[0].qty : 1;
                        c[0] = { type:'STUD', price: parseInt(r.price)+dp, carat: ct, qty: q, gold: goldcolor };
                    }
                    setCart(c);
                    updatetotal(c);
                }
            }
            return r;
        }));
    }
return <>
<section id="product" className="cart-wrapper">
    <div className="container">
        <div className="row">
            <div className="col">
                <div className="cart-heart-wrapper">
    <div className="box2">
        <img src="/images/heart-v2.svg" alt="heart" width="100%" height="100%" loading="lazy" />
        <div className="ear-box">
            <img src="/images/earrings/earring-gold.png" alt="earings" width="100%"
            height="100%" loading="lazy" className={goldcolor + " ear earring"} id="productImage" />
        </div>
    </div>
<div className="heart-2">
    <div className="move-heart-two x4">
        <div className="heart-image">
            <img src="/images/icons/heart.svg" alt="heart" width="60%" height="100%" loading="lazy" />
            <div className="ear-box">
                <img src="/images/icons/ear.svg" alt="earings" width="100%" height="100%" loading="lazy" className="ear" />
            </div>
        </div>
    </div>
</div>
                </div>
            </div>
        </div>

        <div className="filter-wrapper">
            <div className="row">
                <div className="col-lg-4 d-flex justify-content-center flex-column align-items-center">
                    <p className="steps-text">Step 1</p>
                    <h2 className="label">Choose Gold Type
                    <div className="tooltip">
                            <svg className="icon" width="20" height="20" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_29_4)">
                                    <path d="M255.989 0C114.623 0 0 114.623 0 255.989C0 397.355 114.623 512 255.989 512C397.355 512 512 397.355 512 255.989C512 114.623 397.355 0 255.989 0ZM309.28 396.748C296.104 401.95 285.615 405.894 277.748 408.625C268.924 411.507 259.679 412.891 250.398 412.721C234.447 412.721 222.029 408.82 213.187 401.04C204.345 393.259 199.945 383.399 199.945 371.414C199.945 366.755 200.271 361.987 200.921 357.132C201.724 351.602 202.787 346.113 204.106 340.683L220.599 282.429C222.051 276.838 223.308 271.528 224.305 266.587C225.302 261.602 225.778 257.029 225.778 252.868C225.778 245.457 224.24 240.255 221.184 237.33C218.085 234.404 212.255 232.974 203.565 232.974C199.317 232.974 194.939 233.602 190.453 234.924C186.01 236.289 182.153 237.525 178.989 238.738L183.345 220.794C194.137 216.394 204.475 212.624 214.336 209.503C223.353 206.486 232.785 204.892 242.292 204.778C258.135 204.778 270.358 208.636 278.961 216.264C287.522 223.915 291.835 233.862 291.835 246.085C291.835 248.621 291.531 253.085 290.946 259.457C290.438 265.411 289.334 271.299 287.652 277.033L271.246 335.113C269.776 340.367 268.568 345.69 267.627 351.064C266.734 355.536 266.205 360.073 266.045 364.631C266.045 372.346 267.757 377.612 271.225 380.408C274.649 383.204 280.652 384.612 289.147 384.612C293.157 384.612 297.643 383.897 302.714 382.51C307.742 381.123 311.383 379.888 313.68 378.826L309.28 396.748ZM306.376 161.001C298.917 168.035 288.996 171.864 278.745 171.664C267.995 171.664 258.72 168.109 251.005 161.001C247.336 157.774 244.405 153.794 242.412 149.332C240.418 144.871 239.409 140.033 239.453 135.146C239.453 125.069 243.354 116.4 251.005 109.227C258.48 102.136 268.443 98.2678 278.745 98.4557C289.516 98.4557 298.748 102.032 306.376 109.227C314.027 116.4 317.863 125.069 317.863 135.146C317.863 145.267 314.027 153.893 306.376 161.001Z" fill="#030104"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_29_4">
                                        <rect width="512" height="512" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="tooltiptext">Choose Gold Type - Yellow Gold, White Gold or Rose Gold.</span>
                        </div>
                    </h2>
                    <div className="color-picker">
                        <input onChange={goldcolorChanged} name="colorPicker" title="Yellow Gold" className="color earringcolor" data-src="/images/icons/ear-color-2.svg" data-hex="#FCC553" id="yellow" value="yellow" type="radio" />
                        <input onChange={goldcolorChanged} name="colorPicker" title="White Gold" className="color earringcolor" data-src="/images/icons/ear-color.svg" data-hex="#FEB5C3" id="pink" value="white" type="radio" />
                        <input onChange={goldcolorChanged} name="colorPicker" title="Rose Gold" className="color earringcolor" data-src="/images/icons/ear-v2.svg" data-hex="#FF9A84" id="orange" value="rose" type="radio" />
                    </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-center flex-column align-items-center" ref={diamondselector}>
                    <p className="steps-text">Step 2</p>
                    <h2 className="label">Choose Diamond
                        <div className="tooltip">
                            <svg className="icon" width="20" height="20" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_29_4)">
                                    <path d="M255.989 0C114.623 0 0 114.623 0 255.989C0 397.355 114.623 512 255.989 512C397.355 512 512 397.355 512 255.989C512 114.623 397.355 0 255.989 0ZM309.28 396.748C296.104 401.95 285.615 405.894 277.748 408.625C268.924 411.507 259.679 412.891 250.398 412.721C234.447 412.721 222.029 408.82 213.187 401.04C204.345 393.259 199.945 383.399 199.945 371.414C199.945 366.755 200.271 361.987 200.921 357.132C201.724 351.602 202.787 346.113 204.106 340.683L220.599 282.429C222.051 276.838 223.308 271.528 224.305 266.587C225.302 261.602 225.778 257.029 225.778 252.868C225.778 245.457 224.24 240.255 221.184 237.33C218.085 234.404 212.255 232.974 203.565 232.974C199.317 232.974 194.939 233.602 190.453 234.924C186.01 236.289 182.153 237.525 178.989 238.738L183.345 220.794C194.137 216.394 204.475 212.624 214.336 209.503C223.353 206.486 232.785 204.892 242.292 204.778C258.135 204.778 270.358 208.636 278.961 216.264C287.522 223.915 291.835 233.862 291.835 246.085C291.835 248.621 291.531 253.085 290.946 259.457C290.438 265.411 289.334 271.299 287.652 277.033L271.246 335.113C269.776 340.367 268.568 345.69 267.627 351.064C266.734 355.536 266.205 360.073 266.045 364.631C266.045 372.346 267.757 377.612 271.225 380.408C274.649 383.204 280.652 384.612 289.147 384.612C293.157 384.612 297.643 383.897 302.714 382.51C307.742 381.123 311.383 379.888 313.68 378.826L309.28 396.748ZM306.376 161.001C298.917 168.035 288.996 171.864 278.745 171.664C267.995 171.664 258.72 168.109 251.005 161.001C247.336 157.774 244.405 153.794 242.412 149.332C240.418 144.871 239.409 140.033 239.453 135.146C239.453 125.069 243.354 116.4 251.005 109.227C258.48 102.136 268.443 98.2678 278.745 98.4557C289.516 98.4557 298.748 102.032 306.376 109.227C314.027 116.4 317.863 125.069 317.863 135.146C317.863 145.267 314.027 153.893 306.376 161.001Z" fill="#030104"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_29_4">
                                        <rect width="512" height="512" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="tooltiptext">Diamond selection affects the brilliance and sparkle of
                                the jewellry.</span>
                        </div>
                    </h2>
                    <div className="position-relative">
                        <div className="color-picker" ref={diamond}>
                            <input onChange={diamondchanged} name="diamond" className="diamond" id="orange_daimond" value="EF-VVS" type="radio" />
                            <label htmlFor="orange_daimond">EF VVS</label>
                            <input onChange={diamondchanged} name="diamond" className="diamond" id="yellow_daimond" value="IJ-SI" type="radio" />
                            <label htmlFor="yellow_daimond">IJ SI</label>
                        </div>
                        <div id="diamond-price-display">{diamondprice}</div>
                    </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-center flex-column align-items-center position-relative">
                    <p className="steps-text">Step 3</p>
                    <h2 className="label">Choose Gold Carat
                        <div className="tooltip">
                            <svg className="icon" width="20" height="20" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_29_4)">
                                    <path d="M255.989 0C114.623 0 0 114.623 0 255.989C0 397.355 114.623 512 255.989 512C397.355 512 512 397.355 512 255.989C512 114.623 397.355 0 255.989 0ZM309.28 396.748C296.104 401.95 285.615 405.894 277.748 408.625C268.924 411.507 259.679 412.891 250.398 412.721C234.447 412.721 222.029 408.82 213.187 401.04C204.345 393.259 199.945 383.399 199.945 371.414C199.945 366.755 200.271 361.987 200.921 357.132C201.724 351.602 202.787 346.113 204.106 340.683L220.599 282.429C222.051 276.838 223.308 271.528 224.305 266.587C225.302 261.602 225.778 257.029 225.778 252.868C225.778 245.457 224.24 240.255 221.184 237.33C218.085 234.404 212.255 232.974 203.565 232.974C199.317 232.974 194.939 233.602 190.453 234.924C186.01 236.289 182.153 237.525 178.989 238.738L183.345 220.794C194.137 216.394 204.475 212.624 214.336 209.503C223.353 206.486 232.785 204.892 242.292 204.778C258.135 204.778 270.358 208.636 278.961 216.264C287.522 223.915 291.835 233.862 291.835 246.085C291.835 248.621 291.531 253.085 290.946 259.457C290.438 265.411 289.334 271.299 287.652 277.033L271.246 335.113C269.776 340.367 268.568 345.69 267.627 351.064C266.734 355.536 266.205 360.073 266.045 364.631C266.045 372.346 267.757 377.612 271.225 380.408C274.649 383.204 280.652 384.612 289.147 384.612C293.157 384.612 297.643 383.897 302.714 382.51C307.742 381.123 311.383 379.888 313.68 378.826L309.28 396.748ZM306.376 161.001C298.917 168.035 288.996 171.864 278.745 171.664C267.995 171.664 258.72 168.109 251.005 161.001C247.336 157.774 244.405 153.794 242.412 149.332C240.418 144.871 239.409 140.033 239.453 135.146C239.453 125.069 243.354 116.4 251.005 109.227C258.48 102.136 268.443 98.2678 278.745 98.4557C289.516 98.4557 298.748 102.032 306.376 109.227C314.027 116.4 317.863 125.069 317.863 135.146C317.863 145.267 314.027 153.893 306.376 161.001Z" fill="#030104"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_29_4">
                                        <rect width="512" height="512" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="tooltiptext">Gold carat determines the purity of gold</span>
                        </div>
                    </h2>

                    <div className="position-relative">
                        <div className="color-picker">
                            <input name="carat" onChange={caratChanged} className="carat" id="14_kt" value="4" type="radio" />
                            <label htmlFor="14_kt">14 KT</label>
                            <input name="carat" onChange={caratChanged} className="carat" id="18_kt" value="8" type="radio" />
                            <label htmlFor="18_kt">18 KT</label>
                        </div>
                        <div id="price-display">{caratprice}</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="cart-w">
            <div className="apple-wrapper">
                <img src="/images/apple.svg" alt="plant" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="star-right1">
                <img src="/images/star.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="star-right2">
                <img src="/images/star.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="star-right3">
                <img src="/images/star.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="leaf">
                <img src="/images/leaf.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="leaf-light">
                <img src="/images/leaf-light.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="leaf-light2">
                <img src="/images/leaf-light.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="apple">
                <img src="/images/apple.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="unicorn">
                <img src="/images/unicorn.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="teaddy">
                <img src="/images/teaddy.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="row">
                <div className="col-lg-3 order-lg-0 order-1 position-relative">
                    <p className="steps-text text-center">Step 4</p>
                    <h2 className="label text-center d-flex align-items-center justify-content-center gap-2">Choose Earring Extension
                        <div className="tooltip">
                            <svg className="icon" width="20" height="20" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_29_4)">
                                    <path d="M255.989 0C114.623 0 0 114.623 0 255.989C0 397.355 114.623 512 255.989 512C397.355 512 512 397.355 512 255.989C512 114.623 397.355 0 255.989 0ZM309.28 396.748C296.104 401.95 285.615 405.894 277.748 408.625C268.924 411.507 259.679 412.891 250.398 412.721C234.447 412.721 222.029 408.82 213.187 401.04C204.345 393.259 199.945 383.399 199.945 371.414C199.945 366.755 200.271 361.987 200.921 357.132C201.724 351.602 202.787 346.113 204.106 340.683L220.599 282.429C222.051 276.838 223.308 271.528 224.305 266.587C225.302 261.602 225.778 257.029 225.778 252.868C225.778 245.457 224.24 240.255 221.184 237.33C218.085 234.404 212.255 232.974 203.565 232.974C199.317 232.974 194.939 233.602 190.453 234.924C186.01 236.289 182.153 237.525 178.989 238.738L183.345 220.794C194.137 216.394 204.475 212.624 214.336 209.503C223.353 206.486 232.785 204.892 242.292 204.778C258.135 204.778 270.358 208.636 278.961 216.264C287.522 223.915 291.835 233.862 291.835 246.085C291.835 248.621 291.531 253.085 290.946 259.457C290.438 265.411 289.334 271.299 287.652 277.033L271.246 335.113C269.776 340.367 268.568 345.69 267.627 351.064C266.734 355.536 266.205 360.073 266.045 364.631C266.045 372.346 267.757 377.612 271.225 380.408C274.649 383.204 280.652 384.612 289.147 384.612C293.157 384.612 297.643 383.897 302.714 382.51C307.742 381.123 311.383 379.888 313.68 378.826L309.28 396.748ZM306.376 161.001C298.917 168.035 288.996 171.864 278.745 171.664C267.995 171.664 258.72 168.109 251.005 161.001C247.336 157.774 244.405 153.794 242.412 149.332C240.418 144.871 239.409 140.033 239.453 135.146C239.453 125.069 243.354 116.4 251.005 109.227C258.48 102.136 268.443 98.2678 278.745 98.4557C289.516 98.4557 298.748 102.032 306.376 109.227C314.027 116.4 317.863 125.069 317.863 135.146C317.863 145.267 314.027 153.893 306.376 161.001Z" fill="#030104"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_29_4">
                                        <rect width="512" height="512" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="tooltiptext">The detachable and attachable extension goes around the same stem of the earring.</span>
                        </div>
                    </h2>
                    <div className="earrings-wrapper row">
                        <Earrings metal={goldcolor} carat={kt} changelim={changelim} />
                    </div>
                </div>

                <Previewarea modelimage={lim} metal={goldcolor} />

                <div className="col-lg-4 order-1 d-flex align-items-center justify-content-center position-relative">
                    <div className="cart-details-wrapper">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Cart Details</h3>
                            </div>

                            <Cardbody products={cart} gold={goldcolor} carat={kt} setcart={setCartQty} rfc={removeFromCart} />

                            <div className="card-footer">
                            <div className="total-wrapper"><p className="total-price">Total: ₹{total}</p></div>
                                <div className="btn-wrapper">
                                    <div className="btns">
                                        <button className="btn btn-secondary add-tocart-btn" onClick={buynow} id="buynow">Buy Now
                                            <i>
                                                <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.798 20C17.5694 20 18.3123 19.6714 18.8266 19.0929C19.3409 18.5143 19.5837 17.7429 19.4909 16.9786L17.8123 2.4C17.6552 1.03571 16.498 0 15.1194 0H4.39087C3.01229 0 1.85515 1.03571 1.69801 2.4L0.0194364 16.9786C-0.0734207 17.7429 0.169436 18.5143 0.683722 19.0929C1.19801 19.6714 1.94086 20 2.71229 20H16.798ZM1.43372 17.1429L3.11944 2.56429C3.19087 1.91429 3.74087 1.42857 4.39087 1.42857H15.1194C15.7694 1.42857 16.3194 1.91429 16.3909 2.56429L18.0766 17.1429C18.1194 17.5071 18.0052 17.8643 17.7552 18.1429C17.5123 18.4214 17.1694 18.5714 16.798 18.5714H2.71229C2.34087 18.5714 1.99801 18.4214 1.75515 18.1429C1.50515 17.8643 1.39086 17.5071 1.43372 17.1429Z" fill="#FFF"></path>
                                                    <path d="M9.75446 8.57366C12.1178 8.57366 14.0402 6.65123 14.0402 4.28795V3.57366C14.0402 3.17923 13.7203 2.85938 13.3259 2.85938C12.9315 2.85938 12.6116 3.17923 12.6116 3.57366V4.28795C12.6116 5.86338 11.3299 7.14509 9.75446 7.14509C8.17904 7.14509 6.89732 5.86338 6.89732 4.28795V3.57366C6.89732 3.17923 6.57746 2.85938 6.18304 2.85938C5.78861 2.85938 5.46875 3.17923 5.46875 3.57366V4.28795C5.46875 6.65123 7.39118 8.57366 9.75446 8.57366Z" fill="#FFF"></path>
                                                </svg>
                                            </i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="earring-buy-box" ref={animatebuybox}>
                            <div className="cart-buy">
                                <img src="/images/A0.png" alt="buy now" />
                            </div>
                            <div className="cart-earrings">
                                <img src="/images/earring-gold.png" className="cart-rings" alt="earrrings" width="100%" height="100%" loading="lazy" />
                                <img src="/images/earring-gold.png" className="cart-rings" alt="earrrings" width="100%" height="100%" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</>
}

export default Productselector;
