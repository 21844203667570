import { useEffect, useState } from "react";

const Cartitems = (props) => {
    const gold = { yellow: '1', white: '3', rose: '2' };
    const [quantity, setQuantity] = useState(0);
    const removeQty = () => {
        if (quantity <= 1) {
            props.rfc(props.rfc(props.index, props.item.type));
            return;
        }
        let qt = quantity - 1;
        props.setcart(props.index, qt);
        setQuantity(qt);
    }
    const addQty = () => {
        let qt = quantity + 1;
        setQuantity(qt);
        props.setcart(props.index, qt);
    }

    useEffect(() => {
        setQuantity(props.item.qty);
    },[props.item.qty]);

    return <>{(props.item.type === "STUD") ? 
<div className="item">
        <div className="item-name-wrapper">
            <div className="d-flex align-items-center">
                <img src="images/stud.png" alt="earring stud" className={'width-62 '+props.gold} />
                <h4 className="earing-name">Pair of Earring Studs</h4>
            </div>
            <div className="item-weight">
                <span>{props.carat}KT <span className="capitalize">{props.gold}</span> Gold • solitaire diamond</span>
            </div>
        </div>
        <div className="item-quantity-wrapper">
            <div className="d-inline-flex position-relative w-100 align-items-center">
                <div className="qtyminus" onClick={removeQty}>-</div>
                <span className="qty text-center max-width-95">{quantity}</span>
                <div className="qtyplus" onClick={addQty}>+</div>
            </div>
            <p className="mb-0 price">₹ {props.item.price}</p>
        </div>
    </div>
    :
    <div className="item">
        <div className="item-name-wrapper">
            <div className="d-flex align-items-center">
            <img src={props.item.imgsrc.slice(0, -5) + gold[props.gold] + '.png'} className="img-earring-change type_apple" alt={"left earring extension " + props.item.type} width="100%" height="100%" loading="lazy" />
            <img src={props.item.imgsrc.slice(0, -5) + gold[props.gold] + '.png'} className="img-earring-change type_apple right-earring-flip" alt={"right earring extension " + props.item.type} width="100%" height="100%" loading="lazy" />
                <h4 className="earing-name">Pair of {props.item.type}</h4>
            </div>
            <div className="item-weight">
                <span>{props.carat}KT <span className="capitalize">{props.gold}</span> Gold</span>
            </div>
        </div>
        <div className="item-quantity-wrapper">
            <div className="d-inline-flex position-relative w-100 align-items-center">
                <div className="qtyminus" onClick={removeQty}>-</div>
                <span className="qty text-center max-width-95">{quantity}</span>
                <div className="qtyplus" onClick={addQty}>+</div>
            </div>
            <p className="mb-0 price">₹ {props.item.price}</p>
        </div>
    </div>
    }</>
}

export default Cartitems;