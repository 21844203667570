import { useEffect, useState, useRef } from "react";

const gold = { yellow: '1', white: '3', rose: '2' };
const Earrings = ({metal, changelim}) => 
{
    const [listOfJackets, setLOJ] = useState([{name:"", image: "", infoimage: "", price: ""}]);
    useEffect(() => {
        setLOJ([
            { name:"Stars",
				image: `/images/earrings/star/DS-T-2041-4-${gold[metal]}.png`,
				infoimage: "/images/earrings/star/DS-T-2041-4-5.jpg",
                modelimage: `/images/earrings/star/EARRING-4-${gold[metal]}-1.jpg`,
			},
            { name:"Hearts",
				image: `/images/earrings/heart/DS-T-2041-5-${gold[metal]}.png`,
				infoimage: "/images/earrings/heart/DS-T-2041-5-5.jpg",
                modelimage: `/images/earrings/heart/EARRING-5-${gold[metal]}-1.jpg`
			},
            { name:"Flowers",
				image: `/images/earrings/flower/DS-T-2041-7-${gold[metal]}.png`,
				infoimage: "/images/earrings/flower/DS-T-2041-7-5.jpg",
                modelimage: `/images/earrings/flower/EARRING-7-${gold[metal]}-1.jpg`
			},
            { name:"Apples",
				image: `/images/earrings/apple/earring-apple-${gold[metal]}.png`,
				infoimage: "/images/earrings/apple/EARRING-6-8.jpg",
                modelimage: `/images/earrings/apple/EARRING-6-${gold[metal]}-1.jpg`
			},
            { name:"Minis",
				image: `/images/earrings/mini/EARRING-9-${gold[metal]}.png`,
				infoimage: "/images/earrings/mini/EARRING-9-8.jpg",
                modelimage: `/images/earrings/mini/EARRING-9-${gold[metal]}-1.jpg`
			},
            { name:"Crowns",
				image: `/images/earrings/crown/EARRING-3-${gold[metal]}.png`,
				infoimage: "/images/earrings/crown/EARRING-3-8.jpg",
                modelimage: `/images/earrings/crown/EARRING-3-${gold[metal]}-1.jpg`
			},
            { name:"Unicorns",
				image: `/images/earrings/unicorn/EARRING-8-${gold[metal]}.png`,
				infoimage: "/images/earrings/unicorn/EARRING-8-8.jpg",
                modelimage: `/images/earrings/unicorn/EARRING-8-${gold[metal]}-1.jpg`
			},
            { name:"Butterflies",
				image: `/images/earrings/butterfly/EARRING-5-${gold[metal]}.png`,
				infoimage: "/images/earrings/butterfly/EARRING-5-8.jpg",
                modelimage: `/images/earrings/butterfly/EARRING-5-${gold[metal]}-1.jpg`
			},
            { name:"Dolls",
				image: `/images/earrings/doll/EARRING-7-${gold[metal]}.png`,
				infoimage: "/images/earrings/doll/EARRING-7-8.jpg",
                modelimage: `/images/earrings/doll/EARRING-7-${gold[metal]}-1.jpg`
			},
            { name:"Infinities",
				image: `/images/earrings/infinite/EARRING-2-${gold[metal]}.png`,
				infoimage: "/images/earrings/infinite/EARRING-2-8.jpg",
                modelimage: `/images/earrings/infinite/EARRING-2-${gold[metal]}-1.jpg`
			},
            { name:"Bows",
				image: `/images/earrings/bow/EARRING-1-${gold[metal]}.png`,
				infoimage: "/images/earrings/bow/EARRING-1-8.jpg",
                modelimage: `/images/earrings/bow/EARRING-1-${gold[metal]}-1.jpg`
			},
            { name:"Teddies",
				image: `/images/earrings/teddy/EARRING-4-${gold[metal]}.png`,
				infoimage: "/images/earrings/teddy/EARRING-4-8.jpg",
                modelimage: `/images/earrings/teddy/EARRING-4-${gold[metal]}-1.jpg`
			}
        ]);
    }, [metal]);

    const modal = useRef();
    const modalimage = useRef();

    const bst = (event) => {
        let x = event.target.parentNode.getAttribute('data-bs-infoimage');
        modalimage.current.setAttribute('src', x);
    }

    const earringclicked = (imgsrc, name, image) => {
        changelim(imgsrc, name, image);
    }
    return <>
    {
        listOfJackets.map(jk => <div key={jk.name} className="earrings-pair-box">
        <div className="rings-pair item">
            <div className="info-img-wrapper" data-toggle="modal" data-target="#detailmodal" data-bs-infoimage={jk.infoimage} onClick={bst}>
                <img src="/images/info.svg" alt="info" />
            </div>
            <img src={jk.image} className="img-earring-change type_apple" alt="ear rings" width="100%" height="100%" loading="lazy" />
            <img src={jk.image} className="img-earring-change type_apple right-earring-flip" id="fly-earring2" alt="ear rings" width="100%" height="100%" loading="lazy" />
            <div className="add-btn" onClick={() => { earringclicked(jk.modelimage, jk.name, jk.image) }}>
                <img src="/images/icons/plus.png" alt="add" width="100%" height="100%" loading="lazy" />
            </div>
        </div>
    </div>)
    }
    
    <div ref={modal} data-bs-toggle="modal" className="modal fade" id="detailmodal" tabIndex="-1" role="dialog" aria-labelledby="detailmodal-Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="justify-content-end">
                    <span className="close" data-dismiss="modal" aria-label="Close">
                        <span className="custom-popup__close js-close-popup"></span>
                    </span>
                </div>
                <img src="" alt="earring info" className="detail-info-image" ref={modalimage} />
            </div>
        </div>
    </div>
    </>

}

export default Earrings;