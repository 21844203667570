import { useEffect, useRef } from 'react';
import Headerp from '../components/bheader';
import Footer from '../components/footer';

const Contact = () => {
const heading = useRef();
useEffect(() => window.scroll(0,0), []);
const name = useRef(); const email = useRef(); const phone = useRef(); const message = useRef();
const sendContact = (evt) => { let stop=false;
if (name.current.value.length < 3) { name.current.classList.add('is-invalid'); stop=true; }
if (email.current.value.length < 3) { email.current.classList.add('is-invalid'); stop=true; }
if (phone.current.value.length < 10) { phone.current.classList.add('is-invalid'); stop=true; }
if (stop) { return false; }
evt.target.disabled = true; evt.target.innerHTML = "Sending...";
fetch('https://api.trishika.co/concat', {headers: {'Content-Type':'application/json'}, method: 'post',
body: JSON.stringify({name:name.current.value, email:email.current.value, phone:phone.current.value,
    message:message.current.value})
})
.then(res => res.json())
.then(jr => {
heading.current.innerHTML = "Thank you! 😊<br />We've received your message. 🎉<br />Someone will get back to you shortly.👍";
heading.current.nextElementSibling.innerHTML = '';
})
}
return <>
<Headerp />
<div className='container mb-160'>
    <div className='row'>
<div className='col-12 col-md-4'>
<h2>Contact Info</h2>
<div className='contact_info_sec rounded-5'>
<div className="d-flex info_single align-items-center">
<a href="tel:8009054294"><img src="images/call.svg" alt="call-icon" width="10px" height="auto" /> +91 8009 054294</a>
</div>
<div className="d-flex info_single align-items-center">
<a href="mailto:info@trishika.co"><img src="images/email.svg" alt="email-icon" width="10px" height="auto" /> info@trishika.co</a>
</div>
<div className="d-flex info_single align-items-center">
<span><img src="images/pins.svg" alt="location-icon" width="10px" height="auto" /> Surat</span>
</div>
</div>
</div>
    <div className='col-12 col-md-8'>
    <h2 ref={heading}>Contact Us</h2>
    <div>
    <div className='col-12 my-3'>
        <input type="text" ref={name} className='form-control bg-transparent' placeholder='Please enter your name' />
    </div><div className='col-12 my-3'>
        <input type="text" ref={email} className='form-control bg-transparent' placeholder='Please enter your email address' />
        <div className='invalid-feedback'>Please enter a valid email address.</div>
    </div><div className='col-12 my-3'>
    <input type="text" ref={phone} className='form-control bg-transparent' placeholder='Please enter your phone number' />
        <div className='invalid-feedback'>Please enter a valid number.</div>
    </div><div className='col-12 my-3'>
        <textarea ref={message} className='form-control bg-transparent' style={{height:'150px'}} placeholder='Enter your message'></textarea>
    </div><div className='col-12 my-3'>
    <button className='btn btn-primary theme-btn' onClick={sendContact}>Submit</button>
    </div>
    </div>
    </div>
    </div>
</div>
<Footer /></>
}

export default Contact;
