import { useState, useEffect } from "react";
import Orderitem from './orderitem';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    useEffect(() => {
        fetch('https://api.trishika.co/orders-list', {credentials: 'include'}).then(res => res.json()).then(jr => setOrders(jr));
    }, []);
    console.log('render');
    return <div className="py-3"><div className="col-12 mt-3">
        <h3>My Orders</h3>
        </div>
        <div className="col-12 mt-3">
            {orders.map(el => <Orderitem key={el.uuid} {...el} />)}
        </div>
        </div>
}

export default Orders;
