const Explainer = () => <section id="buyearrings" className="video-wrapper">
<div className="container">
    {/* <div className="row">
        <div className="col">
            <div className="video-heading">
                <div className="d-flex">
                    <img src="/images/icons/play-icon.svg" alt="play-icon" width="100%" height="100%" loading="lazy" />
                    <div className="ps-2">
                        <h2 className="v-title">Earrings at a glance</h2>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    <div className="butterfly-wrapper">
        <img src="/images/butterfly.svg" alt="plant" width="100%" height="100%" loading="lazy" />
    </div>
    <div className="star-right">
        <img src="/images/star.svg" alt="horse" width="100%" height="100%" loading="lazy" />
    </div>
    <div className="top-left-cloud">
        <img src="/images/hero-mini-cloud.svg" alt="cloud" width="100%" />
    </div>
    <div className="top-right-cloud">
        <img src="/images/hero-mini-cloud.svg" alt="cloud" width="100%" />
    </div>

    <div className="v-wrapper">
        <div className="row justify-content-center align-items-center">
            <div className="col-md-8 position-relative" id="videoshow">
                <video autoPlay={true} muted={true} loop={true} className="w-100">
                    <source src="/Earrings-3Gold.mp4" type="video/mp4" />
                    Your browser does not support video
                </video>
            </div>
        </div>
    </div>

    <div className="bottom-left-cloud">
        <img src="/images/hero-mini-cloud.svg" alt="cloud" width="100%" />
    </div>
    <div className="bottom-right-cloud">
        <img src="/images/hero-main-cloud.svg" alt="cloud" width="100%" />
    </div>
</div>

<div className="bottom-left-leaf">
    <img src="/images/leaf.svg" alt="leaf" width="100%" height="100%" loading="lazy" />
</div>

<div className="bottom-midlle-leaf">
    <img src="/images/plant-with-leaf.svg" alt="leaf" width="100%" height="100%" loading="lazy" />
</div>
<div className="container">
        <div className="col-md-4 col-12 mx-auto">
            <h2 className="v-title">Buy Earrings in 4 Easy Steps</h2>
        </div>
    </div>
</section>


export default Explainer;