import Header from '../components/pheader';
import Footer from '../components/footer';
import Register from '../components/register';
import Login from '../components/login';
import { useEffect } from 'react';

const Auth = () => {
useEffect(() => {
  fetch('https://api.trishika.co/islogged', {credentials: 'include'})
  .then(res => {
    if (res.status === 200) { // is logged in and has order.
      return window.location.href = '/checkout';
    }
    if (res.status === 202) { // is logged in but does not have order.
      return window.location.href = '/dashboard';
    }
    window.scroll(0,0);
  })
}, []);
return <><Header />
<section className="login">
    <div className="container">
      <div className="row justify-content-center">
        <Register />
        <Login />
        </div>
        </div>
  </section>
<Footer /></>
}

export default Auth;
