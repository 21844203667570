const BHeader = () => <header class="inner-page" id="myHeader">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <div class="container-fluid">
                    <a class="navbar-brand" href="/">
                        <img src="/images/logo.png" alt="logo" width="100%" height="100%" loading="eager" />
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="/">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/#buyearrings">Buy Earrings</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/#review">Reviews</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" aria-current="page" href="/contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div class="d-lg-flex d-none">
                        <div class="user-card-wrapper">
                            <a href="/checkout" class="header-icons">
                                <img src="/images/card-icon.svg" alt="cart-icon" width="100%" height="100%"
                                    loading="eager" />
                            </a>
                            <a href="/auth" class="header-icons">
                                <img src="/images/user-icon.svg" alt="user-icon" width="100%" height="100%"
                                    loading="eager" />
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </header>
export default BHeader;