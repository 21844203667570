import { useEffect } from "react";
import { FreeMode, Pagination } from 'swiper/modules';

const Testimonials = () => {
    useEffect(() => {
        /* eslint-disable */
        let _ = new Swiper('.video-slider', {
            loop: true, effect: "coverflow", grabCursor: true, centeredSlides: true, slidesPerView: "auto",
            coverflowEffect: { rotate: 50, stretch: 0, depth: 100, modifier: 1, },
            pagination: { el: '.swiper-pagination', clickable: true, },
        });
        let __ = new Swiper(".testi-slider", {
            effect: "freeMode", grabCursor: true, centeredSlides: true, slidesPerView: 1,spaceBetween:30,
            autoplay: true, autoplay: { delay: 3000, },
            pagination:{
                clickable: true,
              },
              modules:[FreeMode, Pagination]
        });
        /* eslint-enable */
    }, []);
    return <>
        {/* <section id="review" className="video-slider-wrapper">
            <div className="container">
                <div className="row">
                    <div id="video-slider" className="swiper video-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="video-s-wrapper">
                                    <video width="320" height="240" poster="/placeholder.jpg" controls preload="none">
                                        <source src="https://samplelib.com/lib/preview/mp4/sample-10s.mp4" />
                                    </video>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="video-s-wrapper">
                                    <video width="320" height="240" controls preload="none">
                                        <source src="https://samplelib.com/lib/preview/mp4/sample-10s.mp4" />
                                    </video>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="video-s-wrapper">
                                    <video width="320" height="240" controls preload="none">
                                        <source src="https://samplelib.com/lib/preview/mp4/sample-10s.mp4" />
                                    </video>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="video-s-wrapper">
                                    <video width="320" height="240" controls preload="none">
                                        <source src="https://samplelib.com/lib/preview/mp4/sample-10s.mp4" />
                                    </video>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <section id="review" className="testimonial-slider">
            <div className="horse-left">
                <img src="images/horse.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="container">
                                <div className="row justify-content-center align-items-center">
                    <div className="col-md-10">
                        <div className="swiper testi-slider">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">I'm in love with these earrings! They are perfect for any occasion and the changeable shapes make them incredibly versatile.</p>
                                        <h5 className="u-name">Anubha Kothari</h5>
                                        <span className="u-info">Baroda, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">I love that I can switch up the look of these earrings easily. It's like having multiple pairs in one!" The quality of these earrings is outstanding. They are well-made , durable and skin friendly.</p>
                                        <h5 className="u-name">Aditi Giriya</h5>
                                        <span className="u-info">Chennei, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">A true celebration for my girl!!! Trishika earrings created special moments and memories for me and my daugther creating a forever gift of love .</p>
                                        <h5 className="u-name">Divya</h5>
                                        <span className="u-info">Surat, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">Purchasing my daughter's first real diamond earrings was a truly momentous occasion. These exquisite diamond earrings symbolize the enduring love and pride I have for her, and I'm excited to see her wear them throughout her life as a reminder of our bond and her accomplishments.</p>
                                        <h5 className="u-name">Sangeen Naik</h5>
                                        <span className="u-info">Mumbai, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">These earrings are not only beautiful but also affordable. Great value for the quality.I love how easy it is to switch  from one design to other. It adds a fun element to my daughter jewelry collection.</p>
                                        <h5 className="u-name">Varsha havle</h5>
                                        <span className="u-info">Baroda, India</span>
                                    </div>
                                </div>
                                {/* <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">High-End Jewelry that will be cherish now and throughout her life.These earrings have become my  daughter go-to accessory. she can wear them to school or to any event – they work for any occasion! I appreciate the attention to detail in the design. The stones are securely held in place, and the overall craftsmanship is superb.</p>
                                        <h5 className="u-name">Sujata Anchliya</h5>
                                        <span className="u-info">Pune, India</span>
                                    </div>
                                </div> */}
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">The packaging is lovely, making them a perfect gift choice for birthdays or special occasions.</p>
                                        <h5 className="u-name">Arshi</h5>
                                        <span className="u-info">Nagda, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">I was initially skeptical about the quality of these earrings given their reasonable price, but I was pleasantly surprised. They're well-crafted and sturdy. My daughter wears them daily, and they still look as good as new.</p>
                                        <h5 className="u-name">Mamta</h5>
                                        <span className="u-info">Baroda, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">Trishika earrings exceeded my expectations from start to finish. The easy doorstep delivery, along with package tracking, was seamless. And the moment I laid eyes on the beautiful box housing the earrings, I knew I had made a fantastic choice!</p>
                                        <h5 className="u-name">Sanju Bhanushali</h5>
                                        <span className="u-info">Baroda, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">I appreciate the effort put into crafting products that align with the interests and preferences of baby girls. It's more than just aesthetics; it's about understanding and celebrating this unique phase of her life.</p>
                                        <h5 className="u-name">Zainab</h5>
                                        <span className="u-info">Chennai, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">Being a mom of a teenager, it's challenging to find affordable yet stylish jewelry. Trishika earrings not only meet my budget but also exceed my daughter's style expectations. We're both thrilled with this purchase!</p>
                                        <h5 className="u-name">Swarnai</h5>
                                        <span className="u-info">Chennai, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">superb!! Excellent!! Support small business instaed of brands because they deliver best quality at amazing prices</p>
                                        <h5 className="u-name">Rahul</h5>
                                        <span className="u-info">Indore, India</span>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="t-slide">
                                        <p className="review">I can confidently recommend these earrings to anyone looking for a versatile and stylish accessory. High quality at awesome price!!!</p>
                                        <h5 className="u-name">Neerav</h5>
                                        <span className="u-info">Chennai, India</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="star-right">
                <img src="images/star.svg" alt="horse" width="100%" height="100%" loading="lazy" />
            </div>
        </section>
    </>
}

export default Testimonials;