import { useEffect, useState, useRef } from "react";
import Header from '../components/pheader';
import Footer from '../components/footer';
import Cartitems from "../components/checkout_cartitems";
import { Link } from "react-router-dom";

const Checkout = () => {
    const [subtotal, setSubtotal] = useState('calculating...');
    const [total, setTotal] = useState('calculating...');
    const [taxvalue, setTaxvalue] = useState('calculating...');
    const [items, setItems] = useState([]);
    const email = useRef();
    const phone = useRef();
    const agreetos = useRef();
    const fullname = useRef();
    const address1 = useRef();
    const address2 = useRef();
    const city = useRef();
    const stet = useRef();
    const pincode = useRef();
    const URL = 'https://api.trishika.co';
    const cancelorder = () => {
        if(!window.confirm('Do you want to empty the cart?')) return false;
        fetch(URL + '/remove-cart', {credentials: 'include', method: 'POST'})
        .then(res => res.json())
        .then(rj => window.location.reload());
    }
    useEffect(() => {
        fetch(URL + '/getcarl', {credentials: 'include'})
        .then(res => res.json())
        .then(jr => { email.current.value = jr.email; phone.current.value = jr.mobile; fullname.current.value = jr.name;
            address1.current.value = jr.address1; address2.current.value = jr.address2; city.current.value = jr.city;
            stet.current.value = jr.stet; pincode.current.value = jr.pincode;
            email.current.disabled = true;
        });
        fetch(URL + '/getcart', {credentials: 'include'})
        .then(res => res.json())
        .then(jr => {
            if (jr.success === 'true') {
                return window.location.href = '/';
            }
            jr = jr.filter((j) => j.type !== 'NOSTUD')
            setItems(jr);
            let t = 0;
            jr.map(j => t += j.price*j.qty);
            setSubtotal(t);
            let ft = t + ((t/100) * 3);
            setTotal(ft.toFixed(2));
            setTaxvalue((ft - t).toFixed(2));
        });
    }, []);
    const placeorder = async () => {
        agreetos.current.parentNode.classList.remove('attention');
        Array.from(document.querySelectorAll('.is-invalid')).map(el => el.classList.remove('is-invalid'));
        if (!agreetos.current.checked) {
            agreetos.current.parentNode.classList.add('attention');
            agreetos.current.classList.add('is-invalid');
        }
        let shipping = { phone: phone.current, fullname: fullname.current, address1: address1.current,
        address2: address2.current, city: city.current, stet: stet.current, pincode: pincode.current};
        Object.values(shipping).map(el => (el.value === '') ? el.classList.add('is-invalid') : el.value);
        if (document.querySelectorAll('.is-invalid').length > 0) {
            return false;
        }
        for (let k in shipping) { shipping[k] = shipping[k].value; }
        let pg = await fetch(URL + '/place-holder', { method: 'post', credentials: 'include',
            body: JSON.stringify(shipping), headers: {'Content-Type': 'application/json'}});
        let text = await pg.text();
        if (pg.status !== 200) {
            alert(text);
            return false;
        }
        window.location.href = text;
    }
return <><Header />
<section className="checkout container">
<div className="checkout-title">Checkout</div>
<div className="row justify-content-center">
<div className="col-md-8 checkout-box col-12 mx-auto">
    <div className="row">
    <div className="col-md-6 col-12 checkout-Product">
<h4>Contact Information</h4>
    <div className="mb-3">
<input type="email" className="form-control" placeholder="Enter your email" ref={email} />
</div><div className="mb-3">
<input type="text" className="form-control" placeholder="Enter your mobile number" ref={phone} />
</div>
<h4>Shipping Details</h4>
<div className="row g-3">
    <div className="col-12">
        <input type="text" className="form-control" ref={fullname} placeholder="Enter your name" />
        <div className="invalid-feedback">Please enter full name</div>
    </div>
    <div className="col-12">
        <input type="text" className="form-control" placeholder="Address line 1" ref={address1} />
        <div className="invalid-feedback">Please enter address line 1</div>
    </div>
    <div className="col-12">
        <input type="text" className="form-control" placeholder="Address line 2" ref={address2} />
        <div className="invalid-feedback">Please enter address line 2</div>
    </div>
    <div className="col-12 col-md-4">
        <input type="text" className="form-control" placeholder="City" ref={city} />
        <div className="invalid-feedback">Please enter city</div>
    </div>
    <div className="col-12 col-md-4">
        <input type="text" className="form-control" placeholder="State" ref={stet} />
        <div className="invalid-feedback">Please enter state</div>
    </div>
    <div className="col-12 col-md-4">
        <input type="text" className="form-control" placeholder="Pincode" ref={pincode} />
        <div className="invalid-feedback">... pincode?</div>
    </div>
    <div className="col-12 form-check">
        <input type="checkbox" className="form-check-input" id="agreetos" ref={agreetos} />
        <label htmlFor="agreetos" className="form-check-label">I agree to the <Link to="/pages/terms-and-conditions" target="_blank">terms & conditions</Link>, <Link to="/legal/refund" target="_blank">shipping and refund policy</Link> & <Link to="/pages/privacy-policy" target="_blank">privacy policy</Link>. I also confirm that the details given above are correct.</label>
    </div>
</div>
    </div>
    <div className="col-md-6 col-12">
    <div className="card">
<div className="card-body">
    {
        items.map((product, i) => <Cartitems key={i} item={product} />)
    }
</div>
<div className="card-footer">
<div className="total-wrapper">
    <p className="total-price d-flex py-0 my-0 justify-content-between"><span>Sub Total:</span> <span>₹{subtotal}</span></p>
    <p className="total-price d-flex py-0 my-0 justify-content-between"><span>Taxes (3% GST):</span> <span>₹{taxvalue}</span></p>
    <p className="total-price d-flex py-0 my-0 justify-content-between"><span>Total:</span> <span>₹{total}</span></p>
    </div>
    <div className="btn-wrapper">
        <div className="btns">
            <button className="btn btn-secondary add-tocart-btn" onClick={placeorder} id="placeorder">Place Order
                <i>
                    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.798 20C17.5694 20 18.3123 19.6714 18.8266 19.0929C19.3409 18.5143 19.5837 17.7429 19.4909 16.9786L17.8123 2.4C17.6552 1.03571 16.498 0 15.1194 0H4.39087C3.01229 0 1.85515 1.03571 1.69801 2.4L0.0194364 16.9786C-0.0734207 17.7429 0.169436 18.5143 0.683722 19.0929C1.19801 19.6714 1.94086 20 2.71229 20H16.798ZM1.43372 17.1429L3.11944 2.56429C3.19087 1.91429 3.74087 1.42857 4.39087 1.42857H15.1194C15.7694 1.42857 16.3194 1.91429 16.3909 2.56429L18.0766 17.1429C18.1194 17.5071 18.0052 17.8643 17.7552 18.1429C17.5123 18.4214 17.1694 18.5714 16.798 18.5714H2.71229C2.34087 18.5714 1.99801 18.4214 1.75515 18.1429C1.50515 17.8643 1.39086 17.5071 1.43372 17.1429Z" fill="#FFF"></path>
                        <path d="M9.75446 8.57366C12.1178 8.57366 14.0402 6.65123 14.0402 4.28795V3.57366C14.0402 3.17923 13.7203 2.85938 13.3259 2.85938C12.9315 2.85938 12.6116 3.17923 12.6116 3.57366V4.28795C12.6116 5.86338 11.3299 7.14509 9.75446 7.14509C8.17904 7.14509 6.89732 5.86338 6.89732 4.28795V3.57366C6.89732 3.17923 6.57746 2.85938 6.18304 2.85938C5.78861 2.85938 5.46875 3.17923 5.46875 3.57366V4.28795C5.46875 6.65123 7.39118 8.57366 9.75446 8.57366Z" fill="#FFF"></path>
                    </svg>
                </i>
            </button>
        </div>
    </div>
    <p className="mt-3 text-center cursor" onClick={cancelorder}>empty the cart</p>
</div>
</div>
    </div>
</div>
</div>
</div>
</section>
<Footer />
</>;
}


export default Checkout;