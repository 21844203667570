import Header from "../components/pheader";
import { Link } from "react-router-dom";
import Orders from "../components/orders";
import Settings from "../components/settings";
import { useEffect } from "react";

const Dashboard = ({section}) => {
    useEffect(() => {
        window.scroll(0,0);
        fetch('https://api.trishika.co/islogged', {credentials: 'include'})
        .then(res => {
          if (res.status > 210) {
            return window.location.href = '/auth';
          }
        })
      }, []);
return <>
<Header />
<div className="container">
    <h3>My Profile</h3>
    <div className="row">
        <div className="col-12 mx-auto">
            <div className="row">
            <div className="col-md-2 col-12">
                <div className="nav nav-pills gx-3 row" aria-orientation="vertical">
                    <Link to="/dashboard" className={(section === 'orders') ? "nav-link col-4 col-md-12 theme-btn active" : "nav-link col-4 col-md-12 theme-btn"}>Orders</Link>
                    <Link to="/dashboard/settings" className={(section === 'settings') ? "col-4 col-md-12 nav-link theme-btn active" : "nav-link col-4 col-md-12 theme-btn"}>Settings</Link>
                    <Link to="/logout" className="nav-link theme-btn col-4 col-md-12">Logout</Link>
            </div>
                </div>
            <div className="col-md-10 col-12">
                {(section === "orders") ? <Orders /> : <Settings />}
            </div>
            </div>
    </div>
</div>
</div>
</>};

export default Dashboard;