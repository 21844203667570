import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
    const [activeTab, setActiveTab] = useState("home");
    const setActive = (tabname) => {
        setActiveTab(tabname);
    };

    useEffect(() => {
        window.onscroll = function () { myHeaderTop() };

        var header = document.getElementById("myHeader");
        var sticky = header.offsetTop;

        function myHeaderTop() {
            if (window.pageYOffset > sticky) {
                header.classList.add("fixed-top");
            } else {
                header.classList.remove("fixed-top");
                // document.querySelector('.nav-link').classList.remove('active');
                // document.querySelector('.nav-link').classList.add('active');
            }
        }
    }, []);
    return <header id="myHeader">
        <div className="container">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link className="navbar-brand">
                        <img src="/images/logo.png" alt="logo" width="100%" height="100%" loading="eager" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item" onClick={() => window.scroll(0, 0)}>
                                <Link className={activeTab == 'home' ? 'nav-link active':'nav-link'} to="/" onClick={() => setActive('home')}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <a href="/#buyearrings" className={activeTab == 'buyearing' ? 'nav-link active':'nav-link'} onClick={() => setActive('buyearing')}>Buy Earring</a>
                            </li>
                            <li className="nav-item">
                                <a href="/#review" className={activeTab == 'review' ? 'nav-link active':'nav-link'} onClick={() => setActive('review')}>Reviews</a>
                            </li>
                            <li className="nav-item">
                                <a href="/#contact" className={activeTab == 'contact' ? 'nav-link active':'nav-link'} onClick={() => setActive('contact')}>Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div className="d-lg-flex d-none">
                        <div className="user-card-wrapper">
                            {/* <Link to="/checkout" className="header-icons" id="shopping-cart">
                                <img src="/images/card-icon.svg" alt="cart-icon" width="100%" height="100%" loading="eager" />
                            </Link> */}
                            <Link to="/auth" className="header-icons">
                                <img src="/images/user-icon.svg" alt="user-icon" width="100%" height="100%" loading="eager" />
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </header>
}

export default Header;