import { useParams } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { useEffect, useState, useRef } from "react";

const Legal = () => {
    const {page} = useParams();
    const [title, setTitle] = useState('loading...');
    const content = useRef();
    const redirec = () => window.location.href = "/";
    useEffect(() => {
        window.scroll(0,0);
        fetch('https://api.trishika.co/pagepage/'+page)
        .then(res => (res.ok) ? res.json() : redirec())
        .then(jr => {setTitle(jr.title); content.current.innerHTML = jr.content});
    }, [page])
    return <>
    <Header />
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-8 mx-auto fw-bolder display-6">{title}</div>
        </div>
        <div className="row mt-2">
            <div className="col-12 col-md-8 mx-auto" ref={content}></div>
        </div>
    </div>
    <Footer />
    </>
}

export default Legal;