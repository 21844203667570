import { Link } from "react-router-dom";
import { useState } from "react";

const Footer = () => { const [wa, setWa]  = useState(''); const updateWa = (evt) => setWa(evt.target.value);
const sendwa = (evt) => { evt.target.disabled = true; let regex = new RegExp(/^[6-9]{1}[0-9]{9}$/);
if (!regex.test(wa)) { evt.target.disabled = false; return alert('WhatsApp number is invalid'); }
fetch('https://api.trishika.co/wa-signup', {headers: {'Content-Type':'application/json', 'dnl': 'true'},
method:'post', body: JSON.stringify({number: wa})}).then(r => r.json()).then(_ => { setWa(''); alert('Saved');})
}
return <section id="contact"><footer>
<div className="horse-left">
    <img src="/images/horse.svg" alt="horse" width="100%" height="100%" loading="lazy" />
</div>
<div className="container">
    <div className="row">
        <div className="col-md-3">
            <div className="navbar-brand"> <img src="/images/logo.png" alt="logo" loading="eager" /></div>
        <Link to="/pages/about-us">About us</Link>
        <Link to="/pages/sambhav-diam">Sambhav Diam Advantage</Link>
        <Link to="/pages/certification">Certification</Link>
        <Link to="/pages/affiliation-program">Affiliation Program</Link>
        </div>
        <div className="col-md-3">
            <p className="links-heading">Policies</p>
            {/* <Link to="/pages/terms-and-conditions">Terms and Conditons</Link> */}
            <Link to="/pages/privacy-policy">Privacy Policy</Link>
            <Link to="/pages/shipping-policy">Shipping Policy</Link>
            <Link to="/pages/exchange">Exchange and Buyback</Link>
            <Link to="/pages/cancellation-return">Cancellation and Return</Link>
        </div>
        <div className="col-md-3">
            <p className="links-heading">Guides</p>
            <Link to="/pages/earrings-guide">Earrings Guide</Link>
            <Link to="/pages/diamond-care">Diamond Jewelry Care</Link>
            {/* <Link to="/pages/ear-piercing">Ear Piercing</Link>
            <Link to="/pages/free-shipping">Free Shipping</Link> */}
            <Link to="/pages/faqs">FAQs</Link>
            {/* <Link to="/pages/financing-options">Financing Options</Link> */}
        </div>
        <div className="col-md-3">
        <p className="links-heading">Contact Info</p>
        <div className='contact_info_sec rounded-5'>
        <div className="d-flex info_single align-items-center">
        <a href="tel:+919898501233"><img src="images/call.svg" alt="call-icon" width="10px" height="auto" /> +919898501233</a>
        </div>
        <div className="d-flex info_single align-items-center">
        <a href="mailto:info@trishika.co"><img src="images/email.svg" alt="email-icon" width="10px" height="auto" /> info@trishika.co</a>
        </div>
        <div className="d-flex info_single align-items-center">
        <span><img src="images/pins.svg" alt="location-icon" width="10px" height="auto" /> Surat</span>
        </div>
        </div>
        </div>
    </div>
    <div className="small-leaf-wrapper">
        <img src="/images/fleaf.svg" alt="plant" width="20px" height="100%" loading="lazy" />
    </div>

</div>
<div className="footer-bottom">
    <div className="plant-wrapper">
        <img src="/images/plant-with-leaf.svg" alt="plant" width="70px" height="100%" loading="lazy" />
    </div>
    <div className="leaf-wrapper">
        <img src="/images/leaf.svg" alt="plant" width="120px" height="100%" loading="lazy" />
    </div>
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <p>Copyright 2024 © Trishika | All Rights reserved. </p>
            </div>
            <div className="col-md-6 text-end">
                <div className="social-box">
                    <a href="https://www.facebook.com/profile.php?id=61556320161170&mibextid=ZbWKwL" rel="nofollow noreferrer noopener" target="_blank"><img src="/images/fb.svg" alt="logo" width="auto" height="20px" /></a>
                    <a rel="nofollow noreferrer noopener" target="_blank" href="https://www.instagram.com/trishikajewellery?igsh=ZndiODloNnhlbjl6"><img src="/images/insta.svg" alt="logo" width="auto" height="20px" /></a>
                    {/* <a rel="nofollow noreferrer noopener" target="_blank" href="https://www.youtube.com/@THINKONLYDIAMONDS"><img src="/images/yt.svg" alt="logo" width="auto" height="20px" /></a> */}
                </div>
            </div>
        </div>
    </div>
</div>
</footer>
</section>
}

export default Footer;