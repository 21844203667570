import Cartitems from './cartitems';

const Cardbody = (props) => {
    return <div className="card-body">
{(props.products.length < 1) ? <div className="placeholder-image"><img src="images/cart-placeholder.png" alt="cart placeholder" /></div> :
(
    props.products.map((product, i) => (product.type === 'NOSTUD')
    ? <span key={i}></span>
    : <Cartitems rfc={props.rfc} key={i} gold={props.gold} carat={props.carat} item={product} index={i} setcart={props.setcart} />)
)}
</div>}

export default Cardbody;