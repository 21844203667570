const Cartitems = (props) => {
    const gold = { yellow: '1', white: '3', rose: '2' };
    return <>{(props.item.type === "STUD") ? 
<div className="item">
        <div className="item-name-wrapper">
            <div className="d-flex align-items-center">
                <img src="images/stud.png" alt="earring stud" className={'width-62 '+props.item.gold} />
                <h4 className="earing-name">Pair of Earring Studs</h4>
            </div>
            <div className="item-weight">
                <span>{props.item.carat}KT <span className="capitalize">{props.item.gold}</span> Gold • solitaire diamond</span>
            </div>
        </div>
        <div className="item-quantity-wrapper">
            <div className="d-inline-flex position-relative w-100 align-items-center">
                <span className="qty text-center max-width-95">{props.item.qty}</span>
            </div>
            <p className="mb-0 price">₹ {props.item.price}</p>
        </div>
    </div>
    :
    <div className="item">
        <div className="item-name-wrapper">
            <div className="d-flex align-items-center">
            <img src={props.item.imgsrc.slice(0, -5) + gold[props.item.gold] + '.png'} className="img-earring-change type_apple" alt={"left earring extension " + props.item.type} width="100%" height="100%" loading="lazy" />
            <img src={props.item.imgsrc.slice(0, -5) + gold[props.item.gold] + '.png'} className="img-earring-change type_apple right-earring-flip" alt={"right earring extension " + props.item.type} width="100%" height="100%" loading="lazy" />
                <h4 className="earing-name">Pair of {props.item.type}</h4>
            </div>
            <div className="item-weight">
                <span>{props.item.carat}KT <span className="capitalize">{props.item.gold}</span> Gold</span>
            </div>
        </div>
        <div className="item-quantity-wrapper">
            <div className="d-inline-flex position-relative w-100 align-items-center">
                <span className="qty text-center max-width-95">{props.item.qty}</span>
            </div>
            <p className="mb-0 price">₹ {props.item.price}</p>
        </div>
    </div>
    }</>
}

export default Cartitems;