import { useEffect } from "react";

const Previewarea = ({modelimage, metal}) => {
const gold = { yellow: 0, white: 1, rose: 2 };
let re = new RegExp(/(\/images\/earrings\/\w+\/EARRING-\d-)(\d)(-1\.jpg)/);
let x = modelimage.match(re);
const y_image =  x[1] + '1' + x[3];
const w_image =  x[1] + '3' + x[3];
const r_image =  x[1] + '2' + x[3];
useEffect(() => {
    /* eslint-disable */
var cartThumbSwiper = new Swiper("#cart-slider-thumb", {
    spaceBetween: 10,
    slidesPerView: 3,
});
new Swiper("#cart-slider", {
    spaceBetween: 10,
    effect: 'fade',
    speed: 500,
    allowTouchMove: false,
    initialSlide: parseInt(gold[metal]),
    thumbs: {
        swiper: cartThumbSwiper,
    },
});
}, [metal]);
/* eslint-enable */

return <div className="col-lg-5 order-lg-0">
        <div className="swiper cartSwiper2 swiper-fade swiper-initialized swiper-horizontal swiper-pointer-events swiper-watch-progress swiper-backface-hidden" id="cart-slider">
            <div className="swiper-wrapper" id="swiper-wrapper-51a7252e355439aa" aria-live="polite">
                <div className="swiper-slide yellow-slider-img swiper-slide-visible swiper-slide-active" role="group" aria-label="1 / 3">
                    <div className="girl-img-wrapper">
                        <img src={y_image} className="girl-img" alt="girl with earrings" width="100%" height="100%" loading="lazy" />
<div className="selected-earrings hide">
<div className="d-flex justify-content-center align-items-center">
<img className="earleft earchange" alt="earrrings" width="100%" height="100%" loading="lazy" />
<img src="/images/diamond.png" alt="diamond" className="diamond-extension extension-show" />
</div></div>
                    </div>
                </div>
                <div className="swiper-slide girl-2-img white-slider-img swiper-slide-next x2" role="group" aria-label="2 / 3">
                    <div className="girl-img-wrapper">
                        <img src={w_image} className="girl-img" alt="girl with earrings" width="100%" height="100%" loading="lazy" />
<div className="selected-earrings hide">
<div className="d-flex justify-content-center align-items-center">
<img className="earleft earchange" alt="earrrings" width="100%" height="100%" loading="lazy" />
<img src="/images/diamond.png" alt="diamond" className="diamond-extension extension-show" />
</div></div>
                    </div>
                </div>
                <div className="swiper-slide girl-3-img orange-slider-img x3" role="group" aria-label="3 / 3">
                    <div className="girl-img-wrapper">
                        <img src={r_image} className="girl-img" alt="girl with earrings" width="100%" height="100%" loading="lazy" />
<div className="selected-earrings hide">
<div className="d-flex justify-content-center align-items-center">
<img className="earleft earchange" alt="earrrings" width="100%" height="100%" loading="lazy" />
<img src="/images/diamond.png" alt="diamond" className="diamond-extension extension-show" />
</div></div>
                    </div>
                </div>
            </div>
        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
        <div thumbsslider="" className="swiper cartSwiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden swiper-thumbs" id="cart-slider-thumb">
            <div className="swiper-wrapper x6" id="swiper-wrapper-d1531de335abf1a1" aria-live="polite">
                <div className="swiper-slide yellow-slider-thumb swiper-slide-visible swiper-slide-active swiper-slide-thumb-active x5" role="group" aria-label="1 / 3">
                    <div className="cart-girl-text">
                        <img id="age1_5" src={y_image} alt="earring" />
                    </div>
                </div>
                <div className="swiper-slide white-slider-thumb swiper-slide-visible swiper-slide-next x5" role="group" aria-label="2 / 3">
                    <div className="cart-girl-text">
                        <img id="age5_10" src={w_image} alt="earring" />
                    </div>
                </div>
                <div className="swiper-slide orange-slider-thumb swiper-slide-visible x5" role="group" aria-label="3 / 3">
                    <div className="cart-girl-text">
                        <img id="age10_15" src={r_image} alt="earring" />
                    </div>
                </div>
            </div>
        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
    </div>}

export default Previewarea;