import { useEffect } from "react";

const Brandbanner = () => {
    const bootSpriteSpin = (selector, options) => {
        if ("IntersectionObserver" in window) {
            // Browser supports IntersectionObserver so use that to defer the boot
            let observer = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        observer.unobserve(entry.target);
                        /* eslint-disable */
                        $(entry.target).spritespin(options);
                    }
                });
            });
            observer.observe($(selector)[0]);
        } else {
            // Browser does not support IntersectionObserver so boot instantly
            $(selector).spritespin(options);
            /* eslint-enable */
        }
    }
    useEffect(() => {
        /* eslint-disable */
        bootSpriteSpin("#mySpriteSpin", {
            source: SpriteSpin.sourceArray('/images/360/{frame}.png', { frame: [1, 113], digits: 1 }),
            width: 540,
            height: 500,
            animate: true,
            loop: true,
            frameWrap: true,
            frameStep: 1,
            frameTime: 113, 
        });
        /* eslint-enable */
    }, [])
return <><section className="hero-wrapper hero">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 position-relative z-1 left-hero">
                        <div className="box1 scrollItem">
                            <div className="heart-image">
                                <img src="images/icons/heart.svg" alt="heart" width="60%" height="100%" loading="lazy" />
                                <div className="ear-box">
                                    <img src="images/icons/ear.svg" alt="earings" width="100%" height="100%"
                                        loading="lazy" className="ear" />
                                </div>
                            </div>
                        </div>
                        <div className="heading-wrapper heart-toggle">
                            <h1 className="h-title">Natural Diamond Earrings</h1>
                            <p className="sub-title">Safe &middot; Secure &middot; Comfortable</p>
                        </div>
                        <div className="btn-wrapper">
                            <a href="#product"><button type="button" className="theme-btn">Buy Now <img
                                        src="images/icons/right-arrow.png" className="icon" alt="arrow-icon" width="100%"
                                        height="100%" /></button></a>
                        </div>

                        <div className="min-cloud">
                            <img src="images/hero-mini-cloud-2.svg" alt="mini cloud" width="100%" height="100%"
                                loading="lazy" />
                        </div>

                        <div className="leaf-wrapper">
                            <img src="images/plant-with-leafs-2.svg" alt="plant" width="100%" height="100%"
                                loading="lazy" />
                        </div>

                        <div className="butterfly-wrapper">
                            <img src="images/butterfly.svg" alt="plant" width="100%" height="100%" loading="lazy" />
                        </div>

                        <div className="unicorn-wrapper">
                            <img src="images/unicorn.svg" alt="plant" width="100%" height="100%" loading="lazy" />
                        </div>

                    </div>

                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 position-relative z-1 right-hero">
                        <div className="girl-img-wrapper">
                            <div id='mySpriteSpin'></div>
                        </div>

                        <div className="bg-lines">
                            <img src="images/background/bg-rounds.svg" alt="circle background" width="100%"
                                height="100%" loading="lazy" />
                        </div>
                        <div className="cloud-main">
                            <img src="images/hero-main-cloud.svg" alt="colud big" width="100%" height="100%"
                                loading="lazy" />
                        </div>

                        <div className="min-cloud">
                            <img src="images/hero-mini-cloud.svg" alt="mini cloud" width="100%" height="100%"
                                loading="lazy" />
                        </div>
                        <div className="apple-wrapper">
                            <img src="images/apple.svg" alt="plant" width="100%" height="100%" loading="lazy" />
                        </div>

                        <div className="leaf-flower-wrapper">
                            <img src="images/plant-with-flower.svg" alt="plant" width="100%" height="100%"
                                loading="lazy" />
                        </div>
                    </div>
                </div>

                <div className="bottom-row">
                    <div className="hero-bottom row">
                        <div className="plant-wrapper">
                            <img src="images/plant-with-leaf.svg" alt="plant" width="100%" height="100%" loading="lazy" />
                        </div>

                        <div className="leaf-wrapper">
                            <img src="images/leaf.svg" alt="plant" width="100%" height="100%" loading="lazy" />
                        </div>

                        <div className="col-lg-6">
                            <div className="bottom-left">
                                <div className="flag-wrapper">
                                    <img src="images/flag.png" alt="flag of the india" width="100%" height="100%"
                                        loading="lazy" />
                                </div>
                                <div className="text-wrapper">
                                    <p className="dark-text mb-0">made in india</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bottom-right">
                                <div className="rating-wrapper">
                                    <img src="images/star-icon.png" alt="star" width="100%" height="100%"
                                        loading="lazy" />
                                    <img src="images/star-icon.png" alt="star" width="100%" height="100%"
                                        loading="lazy" />
                                    <img src="images/star-icon.png" alt="star" width="100%" height="100%"
                                        loading="lazy" />
                                    <img src="images/star-icon.png" alt="star" width="100%" height="100%"
                                        loading="lazy" />
                                    <img src="images/star-icon.png" alt="star" width="100%" height="100%"
                                        loading="lazy" />
                                </div>
                                <div className="text-wrapper">
                                    <p className="dark-text mb-0">Award Winning</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-img">
                    <img src="images/hero-bottom-clouds.png" alt="bottom cloud" />
                </div>
            </div>
        </section>

<section className="slider-wrapper">
<div className="container-fluid">
    <div className="row">
        <div className="col">
            <div className="swiper ">
                <div className="marquee">
                    <div className="logo-slider d-flex">
                        {/* <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/100-icon.png" alt="100%" width="100%" height="100%"
                                    loading="lazy" />
                            </div>
                            <div className="text-wrapper">
                                <p className="mb-0 title">100%</p>
                                <span className="sub-title">Buy Back</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/cash-icon.png" alt="cash-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">80%</p>
                                <span className="sub-title">cash Return</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/exchange-icon.png" alt="exchange-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Lifetime</p>
                                <span className="sub-title">exchange</span>
                            </div>
                        </div> */}

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Complete Transparency</p>
                                {/* <span className="sub-title">hallmarked</span> */}
                            </div>
                        </div>
                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Assured Lifetime Maintenance</p>
                                {/* <span className="sub-title">hallmarked</span> */}
                            </div>
                        </div>
                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Fair Deduction Gold Exchange</p>
                                {/* <span className="sub-title">hallmarked</span> */}
                            </div>
                        </div>
                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Fair Price Policy</p>
                                {/* <span className="sub-title">hallmarked</span> */}
                            </div>
                        </div>
                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Responsibly Sourced Products</p>
                                {/* <span className="sub-title">hallmarked</span> */}
                            </div>
                        </div>
                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Guaranteed Buyback</p>
                                {/* <span className="sub-title">hallmarked</span> */}
                            </div>
                        </div>
                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Tested & Certified Diamonds</p>
                                {/* <span className="sub-title">hallmarked</span> */}
                            </div>
                        </div>
                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">BIS</p>
                                <span className="sub-title">hallmarked</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/factory-icon.png" alt="factory-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Directly</p>
                                <span className="sub-title">From factory</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/gift-icon.png" alt="gift-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Best</p>
                                <span className="sub-title">gift</span>
                            </div>
                        </div>
                        {/* <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/100-icon.png" alt="100%" width="100%" height="100%"
                                    loading="lazy" />
                            </div>
                            <div className="text-wrapper">
                                <p className="mb-0 title">100%</p>
                                <span className="sub-title">Buy Back</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/cash-icon.png" alt="cash-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">80%</p>
                                <span className="sub-title">cash Return</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/exchange-icon.png" alt="exchange-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Lifetime</p>
                                <span className="sub-title">exchange</span>
                            </div>
                        </div> */}

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">BIS</p>
                                <span className="sub-title">hallmarked</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/factory-icon.png" alt="factory-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Directly</p>
                                <span className="sub-title">From factory</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/gift-icon.png" alt="gift-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Best</p>
                                <span className="sub-title">gift</span>
                            </div>
                        </div>
                        {/* <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/100-icon.png" alt="100%" width="100%" height="100%"
                                    loading="lazy" />
                            </div>
                            <div className="text-wrapper">
                                <p className="mb-0 title">100%</p>
                                <span className="sub-title">Buy Back</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/cash-icon.png" alt="cash-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">80%</p>
                                <span className="sub-title">cash Return</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/exchange-icon.png" alt="exchange-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Lifetime</p>
                                <span className="sub-title">exchange</span>
                            </div>
                        </div> */}

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/hall-mark-icon.png" alt="hall-mark-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">BIS</p>
                                <span className="sub-title">hallmarked</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/factory-icon.png" alt="factory-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Directly</p>
                                <span className="sub-title">From factory</span>
                            </div>
                        </div>

                        <div className="logo-box">
                            <div className="img-wrapper">
                                <img src="images/icons/gift-icon.png" alt="gift-icon" width="100%"
                                    height="100%" loading="lazy" />
                            </div>

                            <div className="text-wrapper">
                                <p className="mb-0 title">Best</p>
                                <span className="sub-title">gift</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
</>
}

export default Brandbanner;