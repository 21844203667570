import { Link } from "react-router-dom";

const Pheader = () => {
return <header id="myHeader" className="inner-page">
<div className="container">
<nav className="navbar navbar-expand-lg">
<div className="container-fluid">
<Link className="navbar-brand" to="/">
    <img src="/images/logo.png" alt="logo" width="100%" height="100%" loading="eager" />
</Link>
<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
</button>
<div className="collapse navbar-collapse justify-content-end" id="navbarNav">
    <ul className="navbar-nav">
        <li className="nav-item">
            <Link to="/" className="nav-link">Home</Link>
        </li>
        <li className="nav-item">
        <Link to="/contact" className="nav-link">Contact</Link>
        </li>
    </ul>
</div>
<div className="d-lg-flex d-none">
    <div className="user-card-wrapper">
                            
<Link to="/checkout" className="header-icons" id="shopping-cart">
    <img src="/images/card-icon.svg" alt="cart-icon" width="100%" height="100%" loading="eager" />
</Link>
<Link to="/auth" className="header-icons">
    <img src="/images/user-icon.svg" alt="user-icon" width="100%" height="100%" loading="eager" />
</Link>
                </div>
            </div>
        </div>
    </nav>
</div>
</header>
}

export default Pheader;