import Footer from "../components/footer";
import Header from "../components/bheader";
import { useRef, useState } from "react";

const Forgot = () => {
    const email = useRef();
    const [info, setInfo] = useState(false);
    const rr = (e) => {
        let reem = new RegExp(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/);
        if (!reem.test(email.current.value)) {
            email.current.classList.add('is-invalid');
            return false;
        }
        e.target.disabled = true;
        e.target.innerHTML = "Sending...";
        fetch('https://api.trishika.co/req-reset', {headers: {'Content-Type': 'application/json', 'dnl': 55}, method: 'POST',
        body: JSON.stringify({username: email.current.value})}).then(res => res.json())
        .then(jr => { setInfo("If your email is in our system, you will receive a password reset link."); e.target.innerHTML = 'Sent!'; setTimeout(() => window.location.href = '/', 5500);});
    };
    
    return <>
<Header />
<div className="container p-5">
    <div className="row">
        <div className="col-12 col-md-5 mx-auto">
        <div className="login-box">
    <h3>Login</h3>
        <div className="row">
        <div className="col-sm-12 my-3">
            {(info) ? <div className="p-1 alert alert-success">{info}</div> : <></>}
            <input type="email" ref={email} className="form-control" placeholder="Please enter your email address" />
            <div className="invalid-feedback">Please enter your correct email address</div>
        </div>
        </div>
        <div className="col-sm-12">
        <button className="btn btn-primary theme-btn mt-3" onClick={rr}>Send Reset Link</button>
        </div>
    </div>
        </div>
    </div>
</div>
<Footer />
</>
}

export default Forgot;