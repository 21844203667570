import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';

const Register = () => {
    const tos = useRef();
    const fullname = useRef();
    const email = useRef();
    const password1 = useRef();
    const password2 = useRef();
    const finalerror = useRef();
    const mobile = useRef();
    const [emlEr, setEmlEr] = useState('Please enter a valid Email address.');
    const chkemail = (evt) => {
      let reem = new RegExp(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/);
      if (!reem.test(evt.target.value)) { return false; }
      fetch('https://api.trishika.co/checkmeail', {method: 'POST', headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({chkmail: evt.target.value})})
    .then(res => res.json())
    .then(jr => {
      if (jr.exists) {
        evt.target.classList.add('is-invalid');
        evt.target.parentNode.classList.add('attention');
        setEmlEr("Email already exists. Please login instead.");
        setTimeout(() => {
          evt.target.classList.remove('is-invalid'); evt.target.parentNode.classList.remove('attention');
          setEmlEr("Please enter a valid Email address.");
        }, 5000);
      }
    })
    }

    const processregistration = (evt) => {
      let data = {};
      let allinvalid = Array.from(document.querySelectorAll('.is-invalid'));
      allinvalid.map(el => el.classList.remove('is-invalid'));
      let stop = false;
        if (!tos.current.checked) { stop = true;
          tos.current.classList.add('is-invalid');
            tos.current.parentNode.classList.add('attention');
            setTimeout(() => tos.current.parentNode.classList.remove('attention'), 3000);
        }
        let regex = new RegExp(/^[6-9]{1}[0-9]{9}$/);
        if (!regex.test(mobile.current.value)) { stop = true;
            mobile.current.classList.add('is-invalid');
        } else {
          data['mobile'] = mobile.current.value;
        }
        let reem = new RegExp(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/);
        if (!reem.test(email.current.value)) { stop = true;
          email.current.classList.add('is-invalid');
        } else {
          data['email'] = email.current.value;
        }
        if (password1.current.value.length < 5) { stop=true;
          password1.current.classList.add('is-invalid');
          password2.current.classList.add('is-invalid');
        } else {
          data['password1'] = password1.current.value;
        }
        if (fullname.current.value.length < 3) { stop=true;
          fullname.current.classList.add('is-invalid');
        } else {
          data['fullname'] = fullname.current.value;
        }
        if (password1.current.value !== password2.current.value) { stop = true;
          password1.current.classList.add('is-invalid');
          password2.current.classList.add('is-invalid');
        } else {
            data['password1'] = password1.current.value;
          }
        if (stop) { return false; }
        evt.target.disabled = true;
        evt.target.innerHTML = 'Signing up, please wait...';
        fetch('https://api.trishika.co/add-new', {method: 'POST', headers: {dnl: 1, dnt: 1, 'Content-Type':'application/json'},
      body: JSON.stringify(data), credentials: 'include'})
      .then(res => {
        if (res.status === 401) {
          finalerror.current.classList.add('alert','alert-danger','mt-3','rounded-5','py-1');
          finalerror.current.innerHTML = 'Account already exists.<br /><a href="/retrieve">Click here to reset password.</a>';
          evt.target.disabled = false;
          evt.target.innerHTML = 'Sign Up';
          return;
      }
      if (res.status === 201) {
        window.location.href = "/checkout";
      }
    });
    }
    return <div className="col-md-4">
          <h3>Register</h3>
              <div className="col-sm-12 my-3">
                <input type="text" className="bg-transparent form-control" ref={fullname} placeholder="Enter your full name" />
                <div className='invalid-feedback text-center'>Please enter your Full Name</div>
              </div>
              <div className="col-sm-12 my-3">
                <input type="email" className="bg-transparent form-control" onBlurCapture={chkemail} ref={email} placeholder="Enter your email address" />
                <div className='invalid-feedback text-center'>{emlEr}</div>
              </div>
              <div className="col-sm-12 my-3">
                <input type="password" className="bg-transparent form-control" ref={password1} placeholder="Enter your password" />
              </div>
              <div className="col-sm-12 my-3">
                <input type="password" className="bg-transparent form-control" ref={password2} placeholder="Re-Enter your password" />
                <div className='invalid-feedback text-center'>Passwords must match and be longer than 6 characters.</div>
              </div>
              <div className="col-sm-12 my-3">
                <div className='input-group'>
                    <span className='rounded-start-pill input-group-text'>+91 </span>
                <input type="text" className="form-control bg-transparent rounded-end-pill" ref={mobile} placeholder="Mobile no. (for OTP and updates)" />
                <div className='invalid-feedback text-center'>Please enter a valid Mobile number.</div>
                </div>
              </div>
              <div className="col-sm-12 my-3">
                <div className='form-check'>
                  <input className="form-check-input" type="checkbox" id="gridCheck1" ref={tos} />
                  <label className="form-check-label" htmlFor="gridCheck1">
                    I agree to <Link to='/pages/terms-and-conditions' target="_blank">Terms & Conditions</Link> and <Link to='/pages/privacy-policy' target="_blank">Privacy Policy</Link>
                  </label>
                  <div className='invalid-feedback text-center'>You must agree to the Terms & Conditions and Privacy Policy in order to proceed further.</div>
                </div>
              </div>
            <button type="submit" className="btn btn-primary theme-btn" onClick={processregistration}>Sign Up</button>
            <div ref={finalerror}></div>
        </div>
}

export default Register;
