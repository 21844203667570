import { useEffect } from "react";
import Header from "../components/pheader";

const Confirmation = () => {
    const URL = 'https://api.trishika.co';
    useEffect(() => {
        fetch(URL + '/getcart')
        .then(res => res.json())
        .then(jr => console.log(jr));
    }, []);
    return <><Header /></>;
}

export default Confirmation;