import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Header from '../components/pheader';
import Cartitems from "../components/checkout_cartitems";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadAll } from "@tsparticles/all";

const Ps = () => {
    const [status, setStatus] = useState('loading');
    const [cxname, setCxname] = useState('');
    const [order, setOrder] = useState('######');
    const [phone, setPhone] = useState('');
    const [shipdetis, setShipdetis] = useState([]);
    const [subtotal, setSubtotal] = useState('calculating...');
    const [total, setTotal] = useState('calculating...');
    const [taxvalue, setTaxvalue] = useState('calculating...');
    const [items, setItems] = useState([]);
    const [init, setInit] = useState(false);
    const particlesLoaded = (container) => {
        console.log(container);
      };
    useEffect(() => {
        initParticlesEngine(async (engine) => { await loadAll(engine); });
        fetch('https://api.trishika.co/getcarl', {credentials: 'include'}).then(res => res.json())
        .then(jr => { setCxname(jr.name); setOrder(jr.order); setPhone(jr.mobile);
            setShipdetis([ jr.name, jr.address1, jr.address2, jr.city, jr.stet, jr.pincode ]);
        });
        fetch('https://api.trishika.co/getcart', {credentials: 'include'}).then(res => res.json())
        .then(jr => {
            if (jr.success === 'true') {
                return window.location.href = '/';
            }
            jr = jr.filter((j) => j.type !== 'NOSTUD')
            setItems(jr);
            let t = 0;
            jr.map(j => t += j.price*j.qty);
            setSubtotal(t);
            let ft = t + ((t/100) * 5);
            setTotal(ft);
            setTaxvalue(ft - t);
        });
        getStatus();
    }, []);
    const options = useMemo(() => ({
        "autoPlay": true,
        "background": {
          "color": {
            "value": "#fff"
          },
          "image": "",
          "position": "",
          "repeat": "",
          "size": "",
          "opacity": 1
        },
        "backgroundMask": {
          "composite": "destination-out",
          "cover": {
            "color": {
              "value": "#fff"
            },
            "opacity": 1
          },
          "enable": false
        },
        "clear": true,
        "defaultThemes": {},
        "delay": 0,
        "fullScreen": {
          "enable": true,
          "zIndex": -1
        },
        "detectRetina": true,
        "duration": 0,
        "fpsLimit": 120,
        "interactivity": {
          "detectsOn": "window",
          "events": {
            "onClick": {
              "enable": false,
              "mode": []
            },
            "onDiv": {
              "selectors": [],
              "enable": false,
              "mode": [],
              "type": "circle"
            },
            "onHover": {
              "enable": false,
              "mode": [],
              "parallax": {
                "enable": false,
                "force": 2,
                "smooth": 10
              }
            },
            "resize": {
              "delay": 0.5,
              "enable": true
            }
          },
          "modes": {
            "trail": {
              "delay": 1,
              "pauseOnStop": false,
              "quantity": 1
            },
            "attract": {
              "distance": 200,
              "duration": 0.4,
              "easing": "ease-out-quad",
              "factor": 1,
              "maxSpeed": 50,
              "speed": 1
            },
            "bounce": {
              "distance": 200
            },
            "bubble": {
              "distance": 200,
              "duration": 0.4,
              "mix": false,
              "divs": {
                "distance": 200,
                "duration": 0.4,
                "mix": false,
                "selectors": []
              }
            },
            "connect": {
              "distance": 80,
              "links": {
                "opacity": 0.5
              },
              "radius": 60
            },
            "grab": {
              "distance": 100,
              "links": {
                "blink": false,
                "consent": false,
                "opacity": 1
              }
            },
            "push": {
              "default": true,
              "groups": [],
              "quantity": 4
            },
            "remove": {
              "quantity": 2
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4,
              "factor": 100,
              "speed": 1,
              "maxSpeed": 50,
              "easing": "ease-out-quad",
              "divs": {
                "distance": 200,
                "duration": 0.4,
                "factor": 100,
                "speed": 1,
                "maxSpeed": 50,
                "easing": "ease-out-quad",
                "selectors": []
              }
            },
            "slow": {
              "factor": 3,
              "radius": 200
            },
            "light": {
              "area": {
                "gradient": {
                  "start": {
                    "value": "#ffffff"
                  },
                  "stop": {
                    "value": "#ffffff"
                  }
                },
                "radius": 1000
              },
              "shadow": {
                "color": {
                  "value": "#ffffff"
                },
                "length": 2000
              }
            }
          }
        },
        "manualParticles": [],
        "particles": {
          "bounce": {
            "horizontal": {
              "value": 1
            },
            "vertical": {
              "value": 1
            }
          },
          "collisions": {
            "absorb": {
              "speed": 2
            },
            "bounce": {
              "horizontal": {
                "value": 1
              },
              "vertical": {
                "value": 1
              }
            },
            "enable": false,
            "maxSpeed": 50,
            "mode": "bounce",
            "overlap": {
              "enable": true,
              "retries": 0
            }
          },
          "color": {
            "value": "#fff",
            "animation": {
              "h": {
                "count": 0,
                "enable": false,
                "speed": 1,
                "decay": 0,
                "delay": 0,
                "sync": true,
                "offset": 0
              },
              "s": {
                "count": 0,
                "enable": false,
                "speed": 1,
                "decay": 0,
                "delay": 0,
                "sync": true,
                "offset": 0
              },
              "l": {
                "count": 0,
                "enable": false,
                "speed": 1,
                "decay": 0,
                "delay": 0,
                "sync": true,
                "offset": 0
              }
            }
          },
          "effect": {
            "close": true,
            "fill": true,
            "options": {},
            "type": []
          },
          "groups": {},
          "move": {
            "angle": {
              "offset": 0,
              "value": 90
            },
            "attract": {
              "distance": 200,
              "enable": false,
              "rotate": {
                "x": 3000,
                "y": 3000
              }
            },
            "center": {
              "x": 50,
              "y": 50,
              "mode": "percent",
              "radius": 0
            },
            "decay": 0,
            "distance": {},
            "direction": "none",
            "drift": 0,
            "enable": true,
            "gravity": {
              "acceleration": 15,
              "enable": true,
              "inverse": true,
              "maxSpeed": 100
            },
            "path": {
              "clamp": true,
              "delay": {
                "value": 0
              },
              "enable": false,
              "options": {}
            },
            "outModes": {
              "default": "destroy",
              "bottom": "destroy",
              "left": "destroy",
              "right": "destroy",
              "top": "none"
            },
            "random": false,
            "size": false,
            "speed": {
              "min": 10,
              "max": 20
            },
            "spin": {
              "acceleration": 0,
              "enable": false
            },
            "straight": false,
            "trail": {
              "enable": true,
              "length": 10,
              "fill": {
                "color": {
                  "value": "#fff"
                }
              }
            },
            "vibrate": false,
            "warp": false
          },
          "number": {
            "density": {
              "enable": false,
              "width": 1920,
              "height": 1080
            },
            "limit": {
              "mode": "delete",
              "value": 0
            },
            "value": 0
          },
          "opacity": {
            "value": 1,
            "animation": {
              "count": 0,
              "enable": false,
              "speed": 2,
              "decay": 0,
              "delay": 0,
              "sync": false,
              "mode": "auto",
              "startValue": "random",
              "destroy": "none"
            }
          },
          "reduceDuplicates": false,
          "shadow": {
            "blur": 0,
            "color": {
              "value": "#fff"
            },
            "enable": false,
            "offset": {
              "x": 0,
              "y": 0
            }
          },
          "shape": {
            "close": true,
            "fill": true,
            "options": {},
            "type": "line"
          },
          "size": {
            "value": {
              "min": 0.1,
              "max": 50
            },
            "animation": {
              "count": 0,
              "enable": true,
              "speed": 90,
              "decay": 0,
              "delay": 0,
              "sync": true,
              "mode": "auto",
              "startValue": "max",
              "destroy": "min"
            }
          },
          "stroke": {
            "width": 1,
            "color": {
              "value": "#ffffff",
              "animation": {
                "h": {
                  "count": 0,
                  "enable": false,
                  "speed": 1,
                  "decay": 0,
                  "delay": 0,
                  "sync": true,
                  "offset": 0
                },
                "s": {
                  "count": 0,
                  "enable": false,
                  "speed": 1,
                  "decay": 0,
                  "delay": 0,
                  "sync": true,
                  "offset": 0
                },
                "l": {
                  "count": 0,
                  "enable": false,
                  "speed": 1,
                  "decay": 0,
                  "delay": 0,
                  "sync": true,
                  "offset": 0
                }
              }
            }
          },
          "zIndex": {
            "value": 0,
            "opacityRate": 1,
            "sizeRate": 1,
            "velocityRate": 1
          },
          "destroy": {
            "bounds": {},
            "mode": "split",
            "split": {
              "count": 1,
              "factor": {
                "value": 0.333333
              },
              "rate": {
                "value": 100
              },
              "sizeOffset": true,
              "particles": {
                "stroke": {
                  "width": 0
                },
                "color": {
                  "value": [
                    "#ff595e",
                    "#ffca3a",
                    "#8ac926",
                    "#1982c4",
                    "#6a4c93"
                  ]
                },
                "number": {
                  "value": 0
                },
                "opacity": {
                  "value": {
                    "min": 0.1,
                    "max": 1
                  },
                  "animation": {
                    "enable": true,
                    "speed": 0.7,
                    "sync": false,
                    "startValue": "max",
                    "destroy": "min"
                  }
                },
                "shape": {
                  "type": "circle"
                },
                "size": {
                  "value": {
                    "min": 1,
                    "max": 2
                  },
                  "animation": {
                    "enable": true,
                    "speed": 5,
                    "count": 1,
                    "sync": false,
                    "startValue": "min",
                    "destroy": "none"
                  }
                },
                "life": {
                  "count": 1,
                  "duration": {
                    "value": {
                      "min": 1,
                      "max": 2
                    }
                  }
                },
                "move": {
                  "decay": 0.05,
                  "enable": true,
                  "gravity": {
                    "enable": true,
                    "inverse": false,
                    "acceleration": 5
                  },
                  "speed": {
                    "min": 5,
                    "max": 10
                  },
                  "direction": "none",
                  "outModes": "destroy"
                }
              }
            }
          },
          "roll": {
            "darken": {
              "enable": false,
              "value": 0
            },
            "enable": false,
            "enlighten": {
              "enable": false,
              "value": 0
            },
            "mode": "vertical",
            "speed": 25
          },
          "tilt": {
            "value": 0,
            "animation": {
              "enable": false,
              "speed": 0,
              "decay": 0,
              "sync": false
            },
            "direction": "clockwise",
            "enable": false
          },
          "twinkle": {
            "lines": {
              "enable": false,
              "frequency": 0.05,
              "opacity": 1
            },
            "particles": {
              "enable": false,
              "frequency": 0.05,
              "opacity": 1
            }
          },
          "wobble": {
            "distance": 5,
            "enable": false,
            "speed": {
              "angle": 50,
              "move": 10
            }
          },
          "life": {
            "count": 1,
            "delay": {
              "value": 0,
              "sync": false
            },
            "duration": {
              "value": 0,
              "sync": false
            }
          },
          "rotate": {
            "value": 0,
            "animation": {
              "enable": false,
              "speed": 0,
              "decay": 0,
              "sync": false
            },
            "direction": "clockwise",
            "path": true
          },
          "orbit": {
            "animation": {
              "count": 0,
              "enable": false,
              "speed": 1,
              "decay": 0,
              "delay": 0,
              "sync": false
            },
            "enable": false,
            "opacity": 1,
            "rotation": {
              "value": 45
            },
            "width": 1
          },
          "links": {
            "blink": false,
            "color": {
              "value": "#fff"
            },
            "consent": false,
            "distance": 100,
            "enable": false,
            "frequency": 1,
            "opacity": 1,
            "shadow": {
              "blur": 5,
              "color": {
                "value": "#fff"
              },
              "enable": false
            },
            "triangles": {
              "enable": false,
              "frequency": 1
            },
            "width": 1,
            "warp": false
          },
          "repulse": {
            "value": 0,
            "enabled": false,
            "distance": 1,
            "duration": 1,
            "factor": 1,
            "speed": 1
          }
        },
        "pauseOnBlur": true,
        "pauseOnOutsideViewport": true,
        "responsive": [],
        "smooth": false,
        "style": {},
        "themes": [],
        "zLayers": 100,
        "emitters": {
          "autoPlay": true,
          "fill": true,
          "life": {
            "wait": false,
            "count": 0,
            "delay": 0.1,
            "duration": 0.1
          },
          "rate": {
            "quantity": 1,
            "delay": 0.15
          },
          "shape": {
            "options": {},
            "replace": {
              "color": false,
              "opacity": false
            },
            "type": "square"
          },
          "startCount": 0,
          "size": {
            "mode": "percent",
            "height": 0,
            "width": 100
          },
          "direction": "top",
          "particles": {},
          "position": {
            "x": 50,
            "y": 100
          }
        },
        "motion": {
          "disable": false,
          "reduce": {
            "factor": 4,
            "value": true
          }
        }
      }), []);
    const getStatus = () => {
        setStatus('loading');
        fetch('https://api.trishika.co/getstatus', {credentials: 'include'})
        .then(res => res.json())
        .then(jr => { if (jr.success === undefined) { 
            if (jr.payment === 'done') {
                setInit(true);
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 8000);
            }
            setStatus(jr.payment);
        } else {
            window.location.href = "/"; }});
    }
    return <>
    <Header />
    {(init) ? <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      /> : <></> }
    <div className="row justify-content-center">
    <div className="col-md-8  checkout-box">
        <div className="row">
            <div className="col-md-7">
                <div className="d-flex check align-items-center">
                    <div>
                        <img src={"/images/sign_"+status+".svg"} alt="user-icon" width="70px" height="100%"
                            loading="eager" className={status} />
                    </div>
                    <div>
                        <p>Order #{order}</p>
                        <div className="confirm-title">Thank You, {cxname}</div>
                    </div>
                </div>
                <div className="customer-info">
                    <h4>Shipping Information</h4>
                    <div className="address">
                        <p className="customer-info-title"><b>Shipping Address</b></p>
                        <p><b>{cxname}</b></p>
                        {shipdetis.map((sp, i) => <p key={i}>{sp}</p>)}
                        <p className="customer-info-title"><b>Contact Information</b></p>
                        <p>{phone}</p>
                        <p className="customer-info-title"><b>Payment Status</b></p>
                        <p className="capitalize">{status}</p>
                        {(status === 'pending') ? <button className="btn btn-secondary theme-btn mt-3" onClick={getStatus}>Refresh</button> : <></>}
                        {(status === 'done') ? <Link to="/dashboard" className="mt-3 fw-bolder">Click here to go to Dashboard</Link> : <></>}
                    </div>
                </div>
            </div>
            <div className="col-md-5">
            <div className="card">
<div className="card-body">
    {
        items.map((product, i) => <Cartitems key={i} item={product} />)
    }
</div>
<div className="card-footer">
<div className="total-wrapper">
<p className="total-price d-flex py-0 my-0 justify-content-between"><span>Sub Total:</span> <span>₹{subtotal}</span></p>
<p className="total-price d-flex py-0 my-0 justify-content-between"><span>Taxes (5% GST):</span> <span>₹{taxvalue}</span></p>
<p className="total-price d-flex py-0 my-0 justify-content-between"><span>Total:</span> <span>₹{total}</span></p>
</div>
</div>
</div>
            </div>
        </div></div></div>
    </>
};

export default Ps;