import Header from '../components/header';
import Footer from '../components/footer';
import Testimonials from '../components/testimonials';
import Brandbanner from '../components/brandbanner';
import Productselector from '../components/productselector';
import Explainer from '../components/explainer';
import { useEffect, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const App = () => {
    useEffect(() => {
        if (window.location.hash) {
            window.location.href = window.location.hash;
        }
    let elm = document.createElement('script'); elm.setAttribute('src', '/js/custom.js'); document.body.append(elm);
    }, []);
    useLayoutEffect(() => {
        let responisive = gsap.matchMedia();
        responisive.add("(min-width: 992px)", () => {
            // desktop setup code here...
            gsap.registerPlugin(ScrollTrigger);
        
            gsap.to(".box1", {
                scrollTrigger: {
                    trigger: '.box1',
                    start: "top 10px",
                    end: "+=400",
                    scrub: 1,
                    toggleActions: 'play pause resume reset',
                    toggleClass: "active",
                },
                duration: 5,
                x: 1175,
                y: 855,
            });
        
            gsap.to(".move-heart-two", {
                scrollTrigger: {
                    trigger: '.move-heart-two',
                    start: "top 150px",
                    end: "+=400",
                    scrub: 1,
                    toggleActions: 'play pause resume reset',
                    toggleClass: "active",
                },
                duration: 5,
                x: 500,
                y: 1190,
                rotation: 45,
            onComplete() {
                    gsap.to(this.targets()[0], {
                        scrollTrigger: {
                            trigger: '.move-heart-two',
                            start: "top 10px",
                            end: "+=2000",
                            scrub: 1,
                            toggleActions: 'play pause resume reset',
                            toggleClass: "active",
                        },
                        duration: 5,
                        x: -280,
                        y: 2820,
                        rotation: 0,
                    });
                }
            });
        });
        responisive.add("(max-width: 991px)", () => {
            gsap.registerPlugin(ScrollTrigger);
            gsap.to(".box1", {
                scrollTrigger: {
                    trigger: '.box1',
                    start: "top 10px",
                    end: "+=900",
                    scrub: 2,
                    toggleActions: 'play pause resume reset',
                    toggleClass: "active",
                },
                duration: 3,
                x: 270,
                y: 1280,
            });
        
            gsap.to(".move-heart-two", {
                scrollTrigger: {
                    trigger: '.move-heart-two',
                    start: "top 10px",
                    end: "+=2200",
                    scrub: 1,
                    toggleActions: 'play pause resume reset',
                    toggleClass: "active",
                },
                duration: 5,
                x: 290,
                y: 1900,
                rotation: 40,
        
        
                onComplete() {
                    gsap.to(this.targets()[0], {
                        scrollTrigger: {
                            trigger: '.move-heart-two',
                            start: "top 10px",
                            end: "+=1000",
                            scrub: 1,
                            toggleActions: 'play pause resume reset',
                            toggleClass: "active",
                        },
                        duration: 5,
                        x: -15,
                        y: 3152,
                        rotation: 19,
                    });
                }
            });
        });
        
        responisive.add("(max-width: 576px)", () => {
            gsap.registerPlugin(ScrollTrigger);
            gsap.to(".box1", {
                scrollTrigger: {
                    trigger: '.box1',
                    start: "top 0px",
                    end: "+=900",
                    scrub: 2,
                    toggleActions: 'play pause resume reset',
                    toggleClass: "active",
                },
                duration: 3,
                x: 130,
                y: 1350,
            });
        
            gsap.to(".move-heart-two", {
                scrollTrigger: {
                    trigger: '.move-heart-two',
                    start: "top 10px",
                    end: "+=1800",
                    scrub: 1,
                    toggleActions: 'play pause resume reset',
                    toggleClass: "active",
                },
                duration: 5,
                x: 130,
                y: 1900,
                rotation: 40,
        
        
                onComplete() {
                    // console.log('el done')
                    gsap.to(this.targets()[0], {
                        scrollTrigger: {
                            trigger: '.move-heart-two',
                            start: "top 10px",
                            end: "+=1000",
                            scrub: 1,
                            toggleActions: 'play pause resume reset',
                            toggleClass: "active",
                        },
                        duration: 5,
                        x: 0,
                        y: 3152,
                        rotation: 19,
                    });
                }
            });
        });
    });

    const whatsappStyle= {
        position:"fixed",
        width:"50px",
        bottom:"20px",
        right:"20px",
        zIndex:"10",
        borderRadius: "25px",
        filter: "drop-shadow(gray 10px 10px 3px)"
    };

    const phoneStyle={
        position:"fixed",
        width:"50px",
        bottom:"90px",
        right:"20px",
        zIndex:"10",
        borderRadius: "25px",
        filter: "drop-shadow(gray 10px 10px 3px)" 
    };

    const msgStyle={
        position:"fixed",
        width:"50px",
        bottom:"160px",
        right:"20px",
        zIndex:"10",
        borderRadius: "25px",
        filter: "drop-shadow(gray 10px 10px 3px)"
    };
    return <>
<Header />
<Brandbanner />
<Explainer />
<Productselector />
<Testimonials />
<Footer />
<div style={whatsappStyle}>
    <a href='https://wa.me/919898501233' target='_blank'  rel="noreferrer">
        <img src='images\icons\whatsapp.svg' alt='phone'/>
    </a>
</div>
<div style={phoneStyle}>
    <a href="tel:+919898501233" target='_blank'  rel="noreferrer">
        <img src='images\icons\phone.svg' alt='phone'/>
    </a>
</div>
<div style={msgStyle}>
    <a href="sms:+919898501233" target='_blank'  rel="noreferrer">
        <img src='images\icons\message.svg' alt='message'/>
    </a>
</div>
</>}

export default App;