import { useEffect, useRef, useState } from "react";
import { Buffer } from "buffer";

const Settings = () => {

    const email = useRef();
    const phone = useRef();
    const fullname = useRef();
    const password1 = useRef();
    const password2 = useRef();
    const URL = "https://api.trishika.co";
    const [error, setError] = useState(false);
    const [passerror, setPasserror] = useState(false);

    useEffect(() => {
        fetch(URL + '/getcarl', {credentials: 'include'})
        .then(res => res.json())
        .then(jr => { email.current.value = jr.email;
            phone.current.value = jr.mobile;
            fullname.current.value = jr.name;
            email.current.disabled = true;
        });
    }, []);

    const updatePass = (evt) => {
        if (password1.current.value !== password2.current.value) {
            password1.current.classList.add('is-invalid');
            password2.current.classList.add('is-invalid');
            setPasserror("Passwords do not match!");
            return;
        }
        if (password1.current.value.length < 6) {
            password1.current.classList.add('is-invalid');
            password2.current.classList.add('is-invalid');
            password2.current.value = '';
            setPasserror("Password should be more than 6 characters long!");
            return;
        }
        evt.target.disabled = true;
        evt.target.innerHTML = "Updating...";
        fetch(URL + '/etadpu-drowssap', {method: 'PUT', headers: { dnl: true }, keepalive: true, 
    credentials: 'include', body: Buffer.from(password1.current.value).toString('base64')});
    evt.target.innerHTML = "Updated!"; setTimeout(() => window.location.reload(), 2500);
    }

    const updateDetails = (evt) => {
        let regex = new RegExp(/^[6-9]{1}[0-9]{9}$/);
        if (!regex.test(phone.current.value)) {
            phone.current.classList.add('is-invalid');
            return;
        }
        evt.target.disabled = true;
        evt.target.innerHTML = "Updating...";
        let jr = {}; jr.mobile = phone.current.value; jr.name = fullname.current.value;
        fetch(URL + '/setcarl', {credentials: 'include', method: 'post', keepalive: true,
            headers: { 'Content-Type':'application/json' }, body: JSON.stringify(jr)
        }).then(res => { if (res.status === 200) {
            evt.target.innerHTML = "Updated!"; setTimeout(() => window.location.reload(), 2500);
            return; }
            evt.target.innerHTML = "Update";
            evt.target.disabled = false;
            return res.text();
        })
        .then(jr => setError(jr));
    }

return <div className="ps-3">
<h4 className="mt-2">Update Profile</h4>
        <div className="row my-3">
            <div className="col-12 mb-3">
                <input type="text" className="form-control" placeholder="Name" ref={fullname} />
            </div>
            <div className="col-12 mb-3">
                <input type="email" className="form-control" placeholder="Email" ref={email} />
            </div>
            <div className="col-12 mb-3">
                <input type="text" className="form-control" placeholder="Mobile No" ref={phone} />
            </div>
            <div className="col-6 col-md-4 mb-3">
                {(error) ? <span className="d-block mb-3 danger alert alert-danger p-2">{error}</span> : <></>}
        <button onClick={updateDetails} className="btn mt-2 btn-primary theme-btn">Update</button>
            </div>
        </div>

    <h4 className="mt-3">Change Password</h4>
        <div className="row mb-3">
            <div className="col-sm-12">
                <input type="password" ref={password1} className="form-control" placeholder="New Password" />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col-sm-12">
                <input type="password" ref={password2} className="form-control" placeholder="Confirm Password" />
            </div>
        </div>
        <div className="row">
        <div className="col-6 col-md-4 mb-3">
        {(passerror) ? <span className="d-block mb-3 danger alert alert-danger p-2">{passerror}</span> : <></>}
        <button onClick={updatePass} className="btn mt-2 btn-primary theme-btn">Update</button>
        </div>
        </div>
</div>
}

export default Settings;